import React, { useState, useRef, useContext, useEffect } from 'react';
import html2canvas from "html2canvas";

//mui
import { makeStyles } from "@mui/styles";

//components
import CustomButton from '../../../buttons';
import EditableTitle from '../../../learning-aid/components/rtf-title';
import EditableBody from '../../../learning-aid/components/rtf-body';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';
import AudioUploadContainer from '../AudioUploadContainer';
import SuggestedUploadForAudio from './SuggestedUploadForAudio';

const AudioCard = ({
    handleSaveCard,
    isEdit,
    editCardData,
    handleCancel,
    handleUpdateCard,
    titleBody,
    setTitleBody,
    setTitleText,
    setBodyText,
    audioFile,
    setAudioFile,
    audio,
    setAudio,
    onAudioChange,
    handleChangeLaBgImg,
    audioThumbnail
}) => {

    const classes = useStyles();
    const styles = commonStyles();
    const [globalStates] = useContext(GlobalStates);
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
    let capture = useRef();
    let titleRef = useRef(null);
    let bodyRef = useRef(null);

    const handeleTitleChange = (text) => {
        const editor = titleRef.current.getEditor();
        let value = editor.getText().trim();
        setTitleBody(prev => ({ ...prev, title: text }))
        setTitleText(prev => prev = value)
    }

    const handleBodyChange = (text) => {
        const editor = bodyRef.current.getEditor();
        let value = editor.getText().trim();
        setTitleBody(prev => ({ ...prev, body: text }))
        setBodyText(prev => prev = value)
    }

    const handleRemoveAudio = () => {
        console.log("Delete");
        setAudioFile(null);
        setAudio(null);
        setShowDeleteIcon(false);
    };

    const handleUploadVideo = () => {
        setAudio(audio);
    }

    useEffect(() => {
        handleUploadVideo()
    }, [audioFile])

    return (
        <>
            <div className={`${styles.card} ${styles.aspect1}`} ref={capture} onMouseLeave={() => setShowDeleteIcon(false)}>
                <div className={classes.audioBox}>
                    {showDeleteIcon && (
                        <span className={classes.deleteIcon}>
                            <img src="/images/icons/delete-photo.svg" alt="Delete Button" title="Remove Audio" onClick={handleRemoveAudio} />
                        </span>
                    )}
                    {audio ? (
                        <audio controls src={audio} onMouseOver={() => setShowDeleteIcon(true)} />
                    ) : (
                        <AudioUploadContainer
                            handleChange={onAudioChange}
                            textColor="rgb(0, 0, 0, 0.5)"
                            iconColor="#f4511e"
                            accepted={['MP3', 'WAV', 'OGG', 'M4A']}
                        />
                    )}
                </div>
                {/* {showSuggestions ? */}

                <div className={styles.suggestionBox}>
                    <SuggestedUploadForAudio
                        title={'Thumbnail Image:'}
                        audioThumbnail={audioThumbnail}
                        handleChange={handleChangeLaBgImg}
                    />
                </div>
                {/* //    : null} */}
                <EditableTitle ref={titleRef} minHeight={'3rem'} maxHeight={'6rem'} handeleTitleChange={handeleTitleChange} value={titleBody.title} />
                <EditableBody ref={bodyRef} minHeight={'10rem'} maxHeight={'10rem'} handleBodyChange={handleBodyChange} value={titleBody.body} />
            </div>

            {/* <div className={styles.btnBox}>
        <CustomButton btnText="SAVE" bgcolor="#F4511E" textColor="#FFF" fw={800} ls={1} handleClick={handleSaveCard} />
        {isEdit && (
          <CustomButton btnText="CANCEL" bgcolor="#F4511E" textColor="#FFF" fw={800} ls={1} handleClick={handleCancel} />
        )}
      </div> */}
        </>
    );
};

export default AudioCard;

const useStyles = makeStyles(() => ({
    audioBox: {
        borderRadius: '4px',
        minHeight: '50%',
        width: '100%',
        maxWidth: 'calc(420px - 1rem)',
        '& > audio': {
            width: '100%',
            outline: 'none',
            transform: 'translateY(150%)',
        },
        '&:hover': {
            border: '1.6px dashed rgb(204, 204, 204, 0.5)',
        }
    },
    deleteIcon: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0.5rem',
        right: '0.5rem',
        height: '2.2rem',
        width: '2.2rem',
        borderRadius: '50%',
        backgroundColor: 'rgb(112,112,112,0.5)',
        '& > img': {
            height: '1rem',
            width: '1rem',
            filter: 'invert(100%) sepia(95%) saturate(21%) hue-rotate(288deg) brightness(104%) contrast(106%)',
        },
        '&:hover': {
            cursor: 'pointer',
            '& > img': {
                scale: 1.2,
                filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)',
            }
        }
    },
    audio: {
        display: 'none'
    }
}));
