import React, { useState, useContext, useEffect } from 'react';
import styles, { FlexRowDiv } from './style';
import { HeadingLabel, SmallFontLabel } from './components/Label';
import FlatPrimaryButton from './components/FlatPrimaryButton';
import FlatClearButton from './components/FlatClearButton';
import SimpleFormSelect from './components/SimpleFormSelect';
import NumericInput from './components/NumericInput';
import Switch from 'react-ios-switch';
import ToggleSwitch from './components/ToggleSwitch';
import FormControl from './components/FormControl';
import { PASSED_ROLEPLAY_DATA } from './msConstants';

//new
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

//constants
import constants from '../../constants';

//mui
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';

//components
import Label from '../custom-styled/label';


//utils
import useQuery from '../../utils/useQuery';
import useRouter from '../../utils/useRouter';

//services
import challengesServices from '../../services/challenges';


const circleCheck = require('../../assets/circleCheck.png');
const Page4_CompletionCriteria = (props) => {
  const query = useQuery();
  let isEdit = query.get('edit');
  const challengeId = query.get("challenge_id");
  const companyId = query.get("c_id");
  const projectId = query.get("p_id");
  const microskillId = query.get("ms_id");
  const router = useRouter();
  const type = {
    OPTIONS: 'options',
    SWITCH: 'switch',
    BIDIRECTIONALSWITCH: 'bidirection_switch',
    NUMBERINPUT: 'numeric_input',
    WEIGHTAGEINPUT: 'weightage_input',
    LABEL: 'label',
  };

  const rangeOptions = [
    {
      id: 1,
      display_text: 'Minimum',
    },
    {
      id: 2,
      display_text: 'Maximum',
    },
    {
      id: 3,
      display_text: 'Range',
    },
  ];

  const toneTypeOptions = [
    {
      id: 1,
      display_text: 'Minimum',
    },
    {
      id: 2,
      display_text: 'Negative',
    },
    {
      id: 3,
      display_text: 'Neutral',
    },
  ];

  const clarityTypeOptions = [
    {
      id: 1,
      display_text: 'Neutral',
    },
  ];

  const tableItems = [
    {
      id: 1,
      fieldName: 'Duration',
      isEnabled: () => getEnableDuration(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Duration',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedDuration(),
          onSelect: (item) => handleDurationSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          lowerValue: () => getLowerDuration(),
          onLowerSelect: (item) => updateLowerDuration(item),
          value: () => getDuration(),
          onSelect: (item) => updateDuration(item),
          selectedOption: () => getSelectedDuration(),
          unit: 'Minutes',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('Duration'),
          onSelect: (item) => updateWeightageFor('Duration', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableDuration(),
          onSelect: (item) => {
            handleDurationSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 2,
      fieldName: 'Speech Rate',
      isEnabled: () => getEnableSpeechRate(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Speech Rate',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedSpeechRate(),
          onSelect: (item) => handleSpeechRateSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          lowerValue: () => getLowerSpeechRate(),
          onLowerSelect: (item) => updateLowerSpeechRate(item),
          value: () => getSpeechRate(),
          onSelect: (item) => updateSpeechRate(item),
          selectedOption: () => getSelectedSpeechRate(),
          unit: 'Words Per Minute',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('SpeechRate'),
          onSelect: (item) => updateWeightageFor('SpeechRate', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableSpeechRate(),
          onSelect: (item) => {
            handleSpeechRateSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 3,
      fieldName: 'Keywords Match',
      isEnabled: () => getEnableKeywordMatch(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Keywords Match',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedKeywordMatch(),
          onSelect: (item) => handleKeywordMatchSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          lowerValue: () => getLowerKeywordMatchPercent(),
          onLowerSelect: (item) => updateLowerKeywordMatch(item),
          value: () => getKeywordMatchPercent(),
          onSelect: (item) => updateKeywordMatch(item),
          selectedOption: () => getSelectedKeywordMatch(),
          unit: 'Minimum Percent',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('KeyMatch'),
          onSelect: (item) => updateWeightageFor('KeyMatch', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableKeywordMatch(),
          onSelect: (item) => {
            handleKeywordMatchSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 7,
      fieldName: 'Transcript Match',
      isEnabled: () => getEnableTranscript(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Transcript Match',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedTranscript(),
          onSelect: (item) => handleTranscriptSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          lowerValue: () => getLowerTranscriptPercent(),
          onLowerSelect: (item) => updateLowerTranscriptPercent(item),
          value: () => getTranscriptPercent(),
          onSelect: (item) => updateTranscriptPercent(item),
          selectedOption: () => getSelectedTranscript(),
          unit: 'Minimum Percent',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('TranscriptMatch'),
          onSelect: (item) => updateWeightageFor('TranscriptMatch', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableTranscript(),
          onSelect: (item) => {
            handleTranscriptSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 5,
      fieldName: 'Tone',
      isEnabled: () => getEnableTone(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          // title: 'Energy',
          title: 'Tone',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedTone(),
          onSelect: (item) => handleToneSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          lowerValue: () => getLowerTonePercent(),
          onLowerSelect: (item) => updateLowerTonePercent(item),
          value: () => getTonePercent(),
          onSelect: (item) => updateTonePercent(item),
          selectedOption: () => getSelectedTone(),
          unit: 'Minimum',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('Tone'),
          onSelect: (item) => updateWeightageFor('Tone', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableTone(),
          onSelect: (item) => {
            handleToneSwitchChange(item);
          },
        },
      ],
    },

    {
      id: 6,
      fieldName: 'Clarity',
      isEnabled: () => getEnableClarity(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Clarity',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedClarity(),
          onSelect: (item) => handleClaritySelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          lowerValue: () => getLowerClarityPercent(),
          onLowerSelect: (item) => updateLowerClarityPercent(item),
          value: () => getClarityPercent(),
          onSelect: (item) => updateClarityPercent(item),
          selectedOption: () => getSelectedClarity(),
          unit: 'Minimum Percent',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('Clarity'),
          onSelect: (item) => updateWeightageFor('Clarity', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableClarity(),
          onSelect: (item) => {
            handleClaritySwitchChange(item);
          },
        },
      ],
    },
    // {
    //   id: 8,
    //   fieldName: 'EyeContact',
    //   isEnabled: () => getEnableEyeContact(),
    //   components: [
    //     {
    //       id: 1,
    //       type: type.LABEL,
    //       title: 'Eye Contact',
    //     }, {
    //       id: 3,
    //       type: type.NUMBERINPUT,
    //       isEditable: false,
    //       isVisible: false,
    //       lowerValue: () => { return 0 },
    //       onLowerSelect: () => { return 0 },
    //       value: () => { return 0 },
    //       onSelect: () => { return 0 },
    //       selectedOption: () => { return 0 },
    //       unit: '',
    //     },
    //     {
    //       id: 4,
    //       type: type.WEIGHTAGEINPUT,
    //       value: () => getWeightageFor('EyeContact'),
    //       onSelect: (item) => updateWeightageFor('EyeContact', item),
    //     },
    //     {
    //       id: 5,
    //       type: type.SWITCH,
    //       selected: () => getEnableEyeContact(),
    //       onSelect: (item) => {
    //         handleEyeContactSwitchChange(item);
    //       },
    //     },
    //   ],
    // },
    // {
    //   id: 9,
    //   fieldName: 'ConfidenceIndex',
    //   isEnabled: () => getEnableConfidenceIndex(),
    //   components: [
    //     {
    //       id: 1,
    //       type: type.LABEL,
    //       title: 'Confidence Index',
    //     }, {
    //       id: 3,
    //       type: type.NUMBERINPUT,
    //       isEditable: false,
    //       isVisible: false,
    //       lowerValue: () => { return 0 },
    //       onLowerSelect: () => { return 0 },
    //       value: () => { return 0 },
    //       onSelect: () => { return 0 },
    //       selectedOption: () => { return 0 },
    //       unit: '',
    //     },
    //     {
    //       id: 4,
    //       type: type.WEIGHTAGEINPUT,
    //       value: () => getWeightageFor('ConfidenceIndex'),
    //       onSelect: (item) => updateWeightageFor('ConfidenceIndex', item),
    //     },
    //     {
    //       id: 5,
    //       type: type.SWITCH,
    //       selected: () => getEnableConfidenceIndex(),
    //       onSelect: (item) => {
    //         handleConfidenceIndexSwitchChange(item);
    //       },
    //     },
    //   ],
    // },

  ];

  const defaultweightage = () => {
    const items = microSkillState.Completion.filter(
      (elem) => elem.check == '1',
    );
    // console.log("Default Wigth" + items);
    if (items.length > 0) {
      let dr = 100 / items.length;
      dr = dr - parseInt(dr) > 0 ? dr.toFixed(2) : parseInt(dr);
      return dr;
    }
    return 0;
  };

  const distributeEqualWeightage = () => {
    const weightage = defaultweightage();
    const updatedItems = microSkillState.Completion.map((elem) => {
      if (elem.check == '1') {
        elem.weightageValue = weightage;
        elem.weightage = 1;
      } else {
        elem.weightageValue = 0;
        elem.weightage = 0;
      }
      return elem;
    });
    setMicroSkillState({
      ...microSkillState,
      Completion: updatedItems,
    });
  };

  const updateWeightageFor = (criteria, value) => {
    const wv = defaultweightage();
    const weightage = value / wv;
    const criteriaItems = microSkillState.Completion.map((elem) => {
      if (elem.name == criteria) {
        elem.weightage = weightage;
        elem.weightageValue = value;
      }
      return elem;
    });
    setMicroSkillState({
      ...microSkillState,
      Completion: criteriaItems,
    });
  };

  const getWeightageFor = (criteria) => {
    const field = microSkillState.Completion.find(
      (elem) => elem.name == criteria,
    );
    const { weightageValue = defaultweightage() } = field;
    return weightageValue;
  };

  const initialCheckStateForCriteria = (name) => {
    // if (microSkillState.Completion.length <= 6)
    //   microSkillState.Completion.push({
    //     name: 'EyeContact',
    //     check: '0',
    //     weightage: 1,
    //     weightageValue: 12.5,
    //   }, {
    //     name: 'ConfidenceIndex',
    //     check: '0',
    //     weightage: 1,
    //     weightageValue: 12.5,
    //   })
    const duration = microSkillState.Completion.find((obj) => obj.name == name);
    return duration.check == 1 ? true : false;
  };

  const { microSkillState, setMicroSkillState } = props;
  const { fromEdit } = props;
  const [savingInProgress, setsavingInProgress] = useState(false);
  const [shouldLaunch, setShouldLaunch] = useState(false);
  const [contextualMatch, setContextualMatch] = useState(microSkillState?.isContextual);
  const [showContextualMatch, setShowContextualMatch] = useState(initialCheckStateForCriteria('TranscriptMatch'));
  const updateMicroskillForCriterial = (name, value) => {
    const items = microSkillState.Completion.map((obj) => {
      if (obj.name == name) {
        obj.rate = value;
      }
      return obj;
    });
    setMicroSkillState({ ...microSkillState, Completion: items });
  };

  const updateMicroskillCheckForCriterial = (name, value) => {
    const items = microSkillState.Completion.map((obj) => {
      if (obj.name == name) {
        obj.check = value == true ? 1 : 0;
      }
      return obj;
    });
    setMicroSkillState({ ...microSkillState, Completion: items });
  };



  const { setCurrentPage, pages } = props;
  const [launchNowDialogOpen, setLaunchNowDialogOpen] = useState(false);
  //duration row  state
  const [selectedDuration, setSelectedDuration] = useState(rangeOptions[0]);
  const getSelectedDuration = () => {
    return selectedDuration;
  };
  const [lowerDuration, setLowerDuration] = useState();
  const getLowerDuration = () => {
    return lowerDuration;
  };

  const [duration, setDuration] = useState(() => {
    const duration = microSkillState.Completion.find(
      (obj) => obj.name == 'Duration',
    );
    let rate = duration.rate;
    // if (parseInt(rate) == 0) {
      const arr = microSkillState.Sample_transcript.split(' ');
      if (arr.length > 0) {
        const wordLength = arr.filter((word) => word !== '').length;
        let dr = parseInt((60 / 140) * wordLength);
        rate = dr.toString();
      }
    // }
    return rate;
  });
  const getDuration = () => {

    //  minute logic
    let dr = duration / 60;
    dr = dr - parseInt(dr) > 0 ? dr.toFixed(2) : parseInt(dr);

    return dr;

    // new Seconds logic 
    // return duration;
  };

  const getChallengeData = () => {
    try {
      const roleplayChallangeData = JSON.parse(
        window.localStorage.getItem(PASSED_ROLEPLAY_DATA),
      );
      return roleplayChallangeData;
    } catch (error) {
      throw Error('no data found');
    }
  };

  const updateLowerDuration = (item) => {
    setLowerDuration(item);
  };
  const updateDuration = (item) => {
    setDuration(item * 60);
    updateMicroskillForCriterial('Duration', item * 60);
    setMicroSkillState({ ...microSkillState, Time_limit: item * 60 });
  };

  const [enableDuration, setEnableDuration] = useState(() =>
    initialCheckStateForCriteria('Duration'),
  );
  const getEnableDuration = () => {
    return enableDuration;
  };

  const handleDurationSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('Duration', value);
    setEnableDuration(value);
    distributeEqualWeightage();
  };

  const handleDurationSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedDuration.id) {
      setLowerDuration();
      setDuration();
      setSelectedDuration(selectedOption);
    }
  };

  //speech rate row state
  const [selectedSpeechRate, setSelectedSpeechRate] = useState(rangeOptions[0]);
  const getSelectedSpeechRate = () => {
    return selectedSpeechRate;
  };
  const [lowerSpeechRate, setLowerSpeechRate] = useState();
  const getLowerSpeechRate = () => {
    return lowerSpeechRate;
  };
  const [speechRate, setSpeechRate] = useState(() => {
    const speechRate = microSkillState.Completion.find(
      (obj) => obj.name == 'SpeechRate',
    );
    return speechRate.rate;
  });
  const getSpeechRate = () => {
    return speechRate;
  };

  const updateLowerSpeechRate = (item) => {
    setLowerSpeechRate(item);
  };
  const updateSpeechRate = (item) => {
    setSpeechRate(item);
    updateMicroskillForCriterial('SpeechRate', item);
  };

  const [enableSpeechRate, setEnableSpeechRate] = useState(() =>
    initialCheckStateForCriteria('SpeechRate'),
  );
  const getEnableSpeechRate = () => {
    return enableSpeechRate;
  };

  const handleSpeechRateSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('SpeechRate', value);
    setEnableSpeechRate(value);
    distributeEqualWeightage();
  };
  const handleSpeechRateSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedSpeechRate.id) {
      setLowerSpeechRate();
      setSpeechRate();
      setSelectedSpeechRate(selectedOption);
    }
  };

  //keyword match row state
  const [selectedKeywordMatch, setSelectedKeywordMatch] = useState(
    rangeOptions[0],
  );
  console.log(rangeOptions[0]);
  const getSelectedKeywordMatch = () => {
    return selectedKeywordMatch;
  };
  const [lowerkeywordMatchPercent, setLowerKeywordMatchPercent] = useState();
  const getLowerKeywordMatchPercent = () => {
    return lowerkeywordMatchPercent;
  };
  const [keywordMatchPercent, setKeywordMatchPercent] = useState(() => {
    const KeyMatch = microSkillState.Completion.find(
      (obj) => obj.name == 'KeyMatch',
    );
    return KeyMatch.rate;
  });
  const getKeywordMatchPercent = () => {
    return keywordMatchPercent;
  };

  const updateLowerKeywordMatch = (item) => {
    setLowerKeywordMatchPercent(item);
  };
  const updateKeywordMatch = (item) => {
    setKeywordMatchPercent(item);
    updateMicroskillForCriterial('KeyMatch', item);
  };

  const [enableKeywordMatch, setEnableKeywordMatch] = useState(() =>
    initialCheckStateForCriteria('KeyMatch'),
  );
  const getEnableKeywordMatch = () => {
    return enableKeywordMatch;
  };

  const handleKeywordMatchSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('KeyMatch', value);
    setEnableKeywordMatch(value);
    distributeEqualWeightage();
  };
  const handleKeywordMatchSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedKeywordMatch.id) {
      setLowerKeywordMatchPercent();
      setKeywordMatchPercent();
      setSelectedKeywordMatch(selectedOption);
    }
  };

  //sequence row state
  const [selectedSequence, setSelectedSequence] = useState(rangeOptions[0]);
  const getSelectedSequence = () => {
    return selectedSequence;
  };
  const [lowerSequencePercent, setLowerSequencePercent] = useState();
  const getLowerSequencePercent = () => {
    return lowerSequencePercent;
  };
  const [sequencePercent, setSequencePercent] = useState(50);
  const getSequencePercent = () => {
    return sequencePercent;
  };

  const updateLowerSequencePercent = (item) => {
    setLowerSequencePercent(item);
  };
  const updateSequencePercent = (item) => {
    setSelectedSequence(item);
  };

  const [enableSequence, setEnableSequence] = useState(true);
  const getEnableSequence = () => {
    return enableSequence;
  };

  const handleSequenceSwitchChange = (value) => {
    setEnableSequence(value);
  };
  const handleSequenceSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedSequence.id) {
      setLowerSequencePercent();
      setSequencePercent();
      setSelectedSequence(selectedOption);
    }
  };

  //new tone state

  const [selectedTone, setSelectedTone] = useState(rangeOptions[0]);
  const getSelectedTone = () => {
    return selectedTone;
  };
  const [lowerTonePercent, setLowerTonePercent] = useState();
  const getLowerTonePercent = () => {
    return lowerTonePercent;
  };
  const [tonePercent, setTonePercent] = useState(() => {
    const item = microSkillState.Completion.find((obj) => obj.name == 'Tone');
    return item.rate;
  });
  const getTonePercent = () => {
    return tonePercent;
  };

  const updateLowerTonePercent = (item) => {
    setLowerTonePercent(item);
  };
  const updateTonePercent = (item) => {
    setTonePercent(item);
    updateMicroskillForCriterial('Tone', item);
  };

  const [enableTone, setEnableTone] = useState(() =>
    initialCheckStateForCriteria('Tone'),
  );
  const getEnableTone = () => {
    return enableTone;
  };

  const handleToneSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('Tone', value);
    setEnableTone(value);
    distributeEqualWeightage();
  };
  const handleToneSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedTone.id) {
      setLowerTonePercent();
      setTonePercent();
      setSelectedTone(selectedOption);
    }
  };

  /*//old tonerow state
  const [selectedTone, setSelectedTone] = useState(rangeOptions[0]);
  const getSelectedTone = () => {
    return selectedTone;
  };
  const [selectedToneType, setSelectedToneType] = useState(toneTypeOptions[0]);
  const getSelectedToneType = () => {
    return selectedToneType;
  };
  const [enableTone, setEnableTone] = useState(true);
  const getEnableTone = () => {
    return enableTone;
  };

  const handleToneSwitchChange = (value) => {
    setEnableTone(value);
  };
  const handleToneSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    setSelectedTone(selectedOption);
  };
  const handleToneTypeSelect = (id) => {
    const selectedOption = toneTypeOptions.find((option) => option.id === id);
    setSelectedToneType(selectedOption);
  };*/

  //new clarity state

  const [selectedClarity, setSelectedClarity] = useState(rangeOptions[0]);
  const getSelectedClarity = () => {
    return selectedClarity;
  };
  const [lowerClarityPercent, setLowerClarityPercent] = useState();
  const getLowerClarityPercent = () => {
    return lowerClarityPercent;
  };
  const [clarityPercent, setClarityPercent] = useState(() => {
    const item = microSkillState.Completion.find(
      (obj) => obj.name == 'Clarity',
    );
    return item.rate;
  });
  const getClarityPercent = () => {
    return clarityPercent;
  };

  const updateLowerClarityPercent = (item) => {
    setLowerClarityPercent(item);
  };
  const updateClarityPercent = (item) => {
    setClarityPercent(item);
    updateMicroskillForCriterial('Clarity', item);
  };

  const [enableClarity, setEnableClarity] = useState(() =>
    initialCheckStateForCriteria('Clarity'),
  );
  const getEnableClarity = () => {
    return enableClarity;
  };

  const handleClaritySwitchChange = (value) => {
    updateMicroskillCheckForCriterial('Clarity', value);
    setEnableClarity(value);
    distributeEqualWeightage();
  };
  const handleClaritySelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedClarity.id) {
      setLowerClarityPercent();
      setClarityPercent();
      setSelectedClarity(selectedOption);
    }
  };

  //old clarityrow state

  /*const [selectedClarity, setSelectedClarity] = useState(rangeOptions[0]);
  const getSelectedClarity = () => {
    return selectedClarity;
  };
  const [selectedClarityType, setSelectedClarityType] = useState(
    clarityTypeOptions[0],
  );
  const getSelectedClarityType = () => {
    return selectedClarityType;
  };
  const [enableClarity, setEnableClarity] = useState(true);
  const getEnableClarity = () => {
    return enableClarity;
  };

  const handleClaritySwitchChange = (value) => {
    setEnableClarity(value);
  };
  const handleClaritySelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    setSelectedClarity(selectedOption);
  };
  const handleClarityTypeSelect = (id) => {
    const selectedOption = clarityTypeOptions.find(
      (option) => option.id === id,
    );
    setSelectedClarityType(selectedOption);
  };
*/
  //new transcriptrow state

  const [selectedTranscript, setSelectedTranscript] = useState(rangeOptions[0]);
  const getSelectedTranscript = () => {
    return selectedTranscript;
  };
  const [lowerTranscriptPercent, setLowerTranscriptPercent] = useState();
  const getLowerTranscriptPercent = () => {
    return lowerTranscriptPercent;
  };
  const [transcriptPercent, setTranscriptPercent] = useState(() => {
    const item = microSkillState.Completion.find(
      (obj) => obj.name == 'TranscriptMatch',
    );
    return item.rate;
  });
  const getTranscriptPercent = () => {
    return transcriptPercent;
  };

  const updateLowerTranscriptPercent = (item) => {
    setLowerTranscriptPercent(item);
  };
  const updateTranscriptPercent = (item) => {
    setTranscriptPercent(item);
    updateMicroskillForCriterial('TranscriptMatch', item);
  };

  const [enableTranscript, setEnableTranscript] = useState(() =>
    initialCheckStateForCriteria('TranscriptMatch'),
  );
  const getEnableTranscript = () => {
    return enableTranscript;
  };

  const handleTranscriptSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('TranscriptMatch', value);
    setEnableTranscript(value);
    distributeEqualWeightage();
    if (!value) {
      setContextualMatch(false);
      setShowContextualMatch(false)
      setMicroSkillState(prev => ({ ...prev, isContextual: false }))
      return
    }
    setShowContextualMatch(true)
  };
  const handleTranscriptSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedTranscript.id) {
      setLowerTranscriptPercent();
      setTranscriptPercent();
      setSelectedTranscript(selectedOption);
    }
  };



  // Eye Contact State Logic


  // const [enableEyeContact, setEnableEyeContact] = useState(() =>
  //   initialCheckStateForCriteria('EyeContact'),)

  // const getEnableEyeContact = () => {
  //   return enableEyeContact;
  // }

  // const handleEyeContactSwitchChange = (value) => {
  //   updateMicroskillCheckForCriterial('EyeContact', value);
  //   setEnableEyeContact(value);
  //   distributeEqualWeightage();
  //   console.log(value);
  // };


  // Confidence Index State Logic


  // const [enableConfidenceIndex, setEnableConfidenceIndex] = useState(() =>
  //   initialCheckStateForCriteria('ConfidenceIndex'),)

  // const getEnableConfidenceIndex = () => {
  //   return enableConfidenceIndex;
  // }

  // const handleConfidenceIndexSwitchChange = (value) => {
  //   updateMicroskillCheckForCriterial('ConfidenceIndex', value);
  //   setEnableConfidenceIndex(value);
  //   distributeEqualWeightage();
  // };


  // useEffect(() => {
  //  if(enableClarity && enableEyeContact && enableTone){
  //   handleConfidenceIndexSwitchChange(1)
  //   }
  //    else{
  //     handleConfidenceIndexSwitchChange(0)
  //    }
  //   }, [enableClarity,enableEyeContact,enableTone])


  const classes = styles();

  const [forward_xls_toast, set_forward_xls_toast] = useState(false);
  const [forward_xls_toast_msg, set_forward_xls_toast_msg] = useState('');

  const isWeightageDistributionCorrect = () => {
    const total = microSkillState.Completion.reduce(
      (a, b) => a + parseFloat(b.weightageValue),
      0,
    );
    return Math.round(total) == 100;
  };

  const getPayload = (companyId, microskillId, challengeId) => {
    const data = {
      companyId: companyId,
      microskillId: microskillId,
      challengeId: challengeId,
      status: 'COMPLETED',
      context: {
        "id": 0,
        context: "New SpotLight Context or can be exisiting context",
        type: "TEXT"
      },
      scenario: microSkillState.Scenar,
      timeLimit: microSkillState.Time_limit,
      mediaPath: microSkillState.Media_Path,
      extracted: microSkillState.extracted,
      keywords: microSkillState.RequestKeyBox,
      criteria: microSkillState.Completion,
      transcript: microSkillState.Sample_transcript,
      formatted: microSkillState.Formatted_transcript,
      ratingMethod: microSkillState.ratingMethod,
      assistance: microSkillState.learner_Assist,
      practice: microSkillState.learner_Practice,
      maxAttempts: microSkillState.no_Attempts,
      matchKeywords: microSkillState.match_keyword_for_transcript,
      language: microSkillState.languages,
      isTranscript: microSkillState.isTranscript,
      isContextual: microSkillState.isContextual
    }
    if (microSkillState.Media_Path === null) {
      delete data.mediaPath;
    }

    if (microSkillState.RequestKeyBox.length) {
      let temp = [];
      microSkillState.RequestKeyBox.forEach(element => {
        let tempObj = {}
        tempObj.id = element.id;
        tempObj.head = element.head;
        tempObj.keywords = element.keywords;
        temp.push(tempObj)
      });
      data.keywords = temp;
    }

    return data;
  }

  const handleUpdateSpotlight = async (payload, challengeId) => {
    payload = { ...payload, context: microSkillState.context }
    const res = await challengesServices.updateSpotlight(payload);
    if (res.status === 200 || res.status === 201) {
      let oldName = query.get("challengeName");
      let newName = microSkillState.Challenge_Name;
      let updateChallengePayload = {
        companyId: companyId,
        microskillId: microskillId,
        challengeId: challengeId,
        status: "COMPLETED"
      }
      if (oldName !== newName) updateChallengePayload.name = microSkillState.Challenge_Name;
      const res = await challengesServices.updateChallenge(updateChallengePayload);
      setsavingInProgress(false);
      localStorage.setItem('isChallengeUpdated', true);
      router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);

    } else {
      setsavingInProgress(false);
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Internal Server Error');
    }
  }

  const launchAction = async () => {
    if (!isWeightageDistributionCorrect()) {
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Total weightage must be 100.');
      return;
    }
    // setsavingInProgress(true);
    let payload = getPayload(companyId, microskillId, challengeId);
    // return
    if (isEdit) {
      handleUpdateSpotlight(payload, challengeId);
      return
    }
    const res = await challengesServices.addSpotlight(payload);
    if (res.status === 200 || res.status === 201) {
      localStorage.setItem('isChallengeCreated', true)
      let oldName = query.get("challengeName");
      let newName = microSkillState.Challenge_Name;
      let updateChallengePayload = {
        companyId: companyId,
        microskillId: microskillId,
        challengeId: challengeId,
        status: "COMPLETED"
      }
      if (oldName !== newName) updateChallengePayload.name = microSkillState.Challenge_Name;
      await challengesServices.updateChallenge(updateChallengePayload);

      setsavingInProgress(false);
      router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);
    }
    if (res.status === 500) {
      setsavingInProgress(false);
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Internal Server Error');
    }

  };


  const launchNowAction = () => {
    return
    // setLaunchNowDialogOpen(false);
    // setShouldLaunch(false);
    // postMessasgeToMasteroRoleplay();
  };

  // const postMessasgeToMasteroRoleplay = () => {
  //   try {
  //     const roleplayChallangeData = JSON.parse(
  //       window.localStorage.getItem(PASSED_ROLEPLAY_DATA),
  //     );
  //     const { moduleID: modID, projID: prID } = roleplayChallangeData;
  //     window.location.replace(
  //       `https://cms.masteroapp.com/admin/adminindivisualuser.aspx?modID=${modID}&prID=${prID}&challengeID=${microSkillState.Chlng_Id}`,
  //     );
  //   } catch (error) {
  //     throw Error('no data found');
  //   }
  // };


  const saveSelfCriteriaAction = (Chlng_Id) => {
    try {
      let data = {
        Chlng_Id: Chlng_Id,
        Self_Id: microSkillState.Self_Id,
        Completion: microSkillState.Completion,
        Challenge_Name: microSkillState.Challenge_Name,
        Input_key: microSkillState.Input_key,
        Level_Id: microSkillState.Level_Id,
        Mod_Id: microSkillState.Mod_Id,
        Proj_Id: microSkillState.Proj_Id,
        RequestKeyBox: microSkillState.RequestKeyBox,
        Scenar: microSkillState.Scenar,
        Skill_tag: microSkillState.Skill_tag,
        Time_limit: microSkillState.Time_limit,
        Video_name: microSkillState.Video_name,
        Sample_transcript: microSkillState.Sample_transcript,
        Formatted_transcript: microSkillState.Formatted_transcript,
        languages: microSkillState.languages,
        rating_method: microSkillState.rating_method,
        learner_Assist: microSkillState.learner_Assist,
        learner_Practice: microSkillState.learner_Practice,
        attempts: microSkillState.attempts,
        no_Attempts: microSkillState.no_Attempts,
        match_keyword_for_transcript:
          microSkillState.match_keyword_for_transcript,
      };
      //COMMMENTED WHILE REMOVING MOClient
      // const fetch_result = MOClient.saveSelfCriteria(data);
      // fetch_result.then((result) => {
      //   setsavingInProgress(false);
      //   if (result.status !== 200) {
      //     setShouldLaunch(false);
      //     const result_json = result.json();
      //     result_json.then((result_json) => {
      //       //do nothing for now
      //       console.log(result_json);
      //     });
      //   } else {
      //     const async_result_json = result.json();
      //     async_result_json.then((result_json) => {
      //       setMicroSkillState((prevState) => ({
      //         ...prevState,
      //         Self_Id: result_json.d,
      //       }));
      //       if (shouldLaunch) {
      //         setShouldLaunch(false);
      //       }
      //     });
      //   }
      // });
    } catch (error) {
      setsavingInProgress(false);
      setShouldLaunch(false);
      console.error(error);
    }
  };

  // const CustomTableRow = withStyles((theme) => ({
  //   root: {
  //     display: 'flex',
  //     height: '80px',
  //     margin: 5,
  //   },
  //   head: {
  //     display: 'flex',
  //   },
  // }))(TableRow);

  // const CustomTableCell = withStyles((theme) => ({
  //   root: {
  //     padding: '10px 10px !important',
  //     display: 'flex',
  //     minWidth: '100px',
  //     alignItems: 'center',
  //     justifyContent: 'flex-start',
  //     flexBasis: '0',
  //     flexGrow: '1',
  //     fontSize: '12px',
  //     color: '#707070',
  //     textAlign: 'left',
  //     background: '#f5f5f5',
  //   },
  //   head: {
  //     backgroundColor: '#fbfbfb',
  //     color: '#484848',
  //     fontWeight: 'bold',
  //     display: 'flex',
  //     minWidth: '100px',
  //     alignItems: 'center',
  //     flexBasis: '0',
  //     flexGrow: '1',
  //     fontSize: '11px',
  //   },
  // }))(TableCell);

  const CustomTableCell = TableCell;

  const CustomTableRow = TableRow;

  const renderCellComponent = (component, index, isEnabled) => {
    let selectedOption = null;
    const { isEditable = true, isVisible = true } = component;
    if ('selectedOption' in component) {
      selectedOption = component.selectedOption();
    }
    switch (component.type) {
      case type.LABEL:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '25%' }}>
            <SmallFontLabel style={{ opacity: !isEnabled ? 0.4 : 1 }}>
              {component.title}
            </SmallFontLabel>
          </CustomTableCell>
        );
        // case type.OPTIONS:
        return (
          <CustomTableCell
            align="left"
            style={index == 2 ? { maxWidth: '50%' } : { maxWidth: '25%' }}>
            {isEnabled && (
              <div
                style={{ width: '100%', maxWidth: '160px', minWidth: '100px' }}>
                <SimpleFormSelect
                  onSelect={(e) => component.onSelect(e)}
                  autoClose={true}
                  options={component.options}
                  selected={
                    (component.selected() &&
                      component.selected().display_text) ||
                    ''
                  }
                  text={
                    (component.selected() &&
                      component.selected().display_text) ||
                    ''
                  }
                />
                {index == 2 && <SmallFontLabel></SmallFontLabel>}
              </div>
            )}
          </CustomTableCell>
        );
      case type.WEIGHTAGEINPUT:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '10%' }}>
            {isEnabled && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <NumericInput
                  width={'100%'}
                  maxWidth={'85px'}
                  minWidth={'85px'}
                  type="number"
                  min={0}
                  max={100}
                  textAlign={'center'}
                  stepperbg={'transparent'}
                  value={component.value()}
                  backgroundColor={'transparent'}
                  onChangeText={(e) => component.onSelect(e)}
                />
              </div>
            )}
          </CustomTableCell>
        );
      case type.NUMBERINPUT:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '50%' }}>
            {isEnabled && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {selectedOption && selectedOption.id == 3 ? (
                  <>
                    <div style={{ marginRight: '10px' }}>
                      <NumericInput
                        width={'100%'}
                        maxWidth={'85px'}
                        minWidth={'85px'}
                        type="number"
                        backgroundColor={'transparent'}
                        placeholder={'Lower range'}
                        min={Number.MIN_VALUE}
                        textAlign={'center'}
                        max={parseInt(
                          component.value()
                            ? component.value()
                            : Number.MAX_VALUE,
                        )}
                        stepperbg={'transparent'}
                        value={component.lowerValue()}
                        onChangeText={(e) =>
                          component.onLowerSelect(parseInt(e))
                        }
                      />
                    </div>
                    <NumericInput
                      width={'100%'}
                      maxWidth={'85px'}
                      minWidth={'85px'}
                      type="number"
                      textAlign={'center'}
                      placeholder={'Upper range'}
                      stepperbg={'transparent'}
                      backgroundColor={'transparent'}
                      min={parseInt(
                        component.lowerValue() ? component.lowerValue() : 0,
                      )}
                      max={Number.MAX_VALUE}
                      value={component.value()}
                      onChangeText={(e) => component.onSelect(e)}
                    />
                  </>
                ) : !isEditable ? (
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '85px',
                      minWidth: '85px',
                      border: '0.20000000298023224px dashed #707070',
                      borderRadius: 2,
                      height: 27.3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // opacity: isVisible ? "1" : "0",
                    }}>
                    <label
                      style={{
                        font: 'normal normal normal 12px Open Sans',
                        color: '#707070',
                        marginRight: '10px',
                      }}>
                      {component.value()}
                    </label>
                  </div>
                ) : (
                  <NumericInput
                    width={'100%'}
                    maxWidth={'85px'}
                    minWidth={'85px'}
                    type="number"
                    min={0}
                    textAlign={'center'}
                    stepperbg={'transparent'}
                    value={component.value()}
                    backgroundColor={'transparent'}
                    onChangeText={(e) => component.onSelect(e)}
                  />
                )}
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontSize: '0.6rem',
                  }}>
                  {component.unit}
                </SmallFontLabel>
              </div>
            )}
          </CustomTableCell>
        );
      case type.SWITCH:
        return (
          <CustomTableCell
            align="left"
            style={{ maxWidth: '15%', justifyContent: 'center' }}>
            <Switch
              checked={component.selected()}
              onChange={(e) => component.onSelect(e)}
            />
          </CustomTableCell>
        );

      case type.BIDIRECTIONALSWITCH:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '25%' }}>
            {isEnabled && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  {component.leftOption}
                </SmallFontLabel>
                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                  <ToggleSwitch
                    bidirectional={'true'}
                    checked={component.selected()}
                    onChange={(e) => {
                      component.onSelect(!component.selected());
                    }}
                  />
                </div>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  {component.rightOption}
                </SmallFontLabel>
              </div>
            )}
          </CustomTableCell>
        );

      default:
        return <></>;
    }
  };
  const renderRow = tableItems.map((item) => (
    <CustomTableRow >

      {item.components.map((component, index) =>
        renderCellComponent(component, index, item.isEnabled()),
      )}
    </CustomTableRow>
  ));

  const renderLaunchNowDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={false}
          open={launchNowDialogOpen}
          onClose={() => {
            setLaunchNowDialogOpen(false);
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: '320px',
              maxHeight: '595px',
            }}>
            <img
              src={circleCheck}
              style={{ width: '7rem', marginTop: '3.7rem' }}
            />
            <HeadingLabel
              style={{
                color: '#238817',
                font: 'normal normal bold 1.5rem Montserrat',
                width: '20rem',
                textAlign: 'center',
                marginTop: '3.7rem',
                lineHeight: '2.5rem',
              }}>
              {fromEdit
                ? 'Role Play Edited Successfuly.'
                : 'Role Play successfully created!'}
            </HeadingLabel>

            <FlatClearButton
              style={{
                padding: '15px 30px',
                minWidth: '20%',
                marginTop: '3rem',
                marginBottom: '1.5rem',
                font: 'normal normal bold 0.5rem Montserrat',
              }}
              onClick={launchNowAction}>
              {fromEdit ? 'OK' : 'Launch Now'}
            </FlatClearButton>
          </div>
        </Dialog>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className={classes.descriptionStyle}>
        <div style={{ flexDirection: 'row' }}>
          <ErrorOutlineIcon sx={{ color: '#F4511E' }} />
          <label
            style={{
              verticalAlign: 'top',
              marginLeft: 10,
              font: 'normal normal bold 25px Open Sans',
              color: '#4F0060',
            }}>
            How to use the metrics:
          </label>
        </div>
        <label
          style={{
            font: 'normal normal 800 18px Open Sans',
            color: '#707070',
          }}>
          Duration:
        </label>
        <p
          style={{
            marginTop: 5,
            textAlign: 'left',
            font: 'normal normal normal 18px Open Sans',
            color: '#707070',
          }}>
          Recommended duration is the ideal length of the conversation that you
          would want the learners to record. They can go beyond this duration
          but it would affect their scoring
        </p>
      </div>
    );
  };

  const handleContextualMatch = () => {
    setContextualMatch(!contextualMatch);
    setMicroSkillState(prev => ({ ...prev, isContextual: !contextualMatch }))
  }



  return (
    <>
      {/* <div className={classes.heading_container}>
        <HeadingLabel>Create Roleplay Challenge Below</HeadingLabel>
      </div> */}
      <FlexRowDiv className={classes.keywordContainer}>
        <div className={classes.criteriaMainContainer}>
          <div className={classes.criteriaLeftContainerStyle}>
            <div>
              <HeadingLabel>Completion Criteria</HeadingLabel>
            </div>
            <div
              style={{
                marginBottom: 30,
                width: '100%',
                maxHeight: "50vh",
                overflow: 'auto',
              }}>
              <Table>
                <TableBody>
                  {renderRow}
                </TableBody>
              </Table>
            </div>
            {constants.SPOTLIGHT_CONTEXTUAL_MATCH_ACCESS.includes(companyId) && showContextualMatch ?
              <div style={{ marginLeft: '0.8rem' }}>
                <Checkbox
                  id='contextual-match'
                  checked={contextualMatch}
                  onChange={handleContextualMatch}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: contextualMatch && '#f4511e !important' }}
                />
                <Label labelText={'Contextual match'} htmlFor={'contextual-match'} />
              </div> : null
            }

          </div>
          <div className={classes.criteriaRightContainerStyle}>
            {renderDescription()}
          </div>
        </div>
      </FlexRowDiv>
      {renderLaunchNowDialog()}
      {/* <div
        className={`${classes.action_btn_container}  ${classes.keyword_action_btn}`}>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          onClick={saveAction}>
          Save
        </FlatPrimaryButton>
        <div className={classes.save_btn_container}>
          <FlatClearButton style={{ padding: '15px 30px', minWidth: '20%' }}>
            Launch
          </FlatClearButton>
        </div>
      </div> */}
      <FormControl className={classes.action_btn_container}>
        {savingInProgress ?

          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            sx={{
              minWidth: '130px',
              width: '134px',
              height: '47px',
              padding: '15px 30px',
              border: '2px solid rgba(0, 0, 0, 0.12) !important',
              fontWeight: 700,
              fontFamily: 'Montserrat',
            }}
          >
            SAVING...
          </LoadingButton> :
          <FlatPrimaryButton
            style={{
              minWidth: '130px',
              width: '150px',
              padding: '15px 30px',
            }}
            onClick={launchAction}>
            SAVE
          </FlatPrimaryButton>}
      </FormControl>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => {
          set_forward_xls_toast(false);
          set_forward_xls_toast_msg('');
        }}
        message={<span id="message-id">{forward_xls_toast_msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              set_forward_xls_toast(false);
              set_forward_xls_toast_msg('');
            }}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default Page4_CompletionCriteria;

const custom_styles = makeStyles({});


