import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    reportBuilder: {
        padding: 20,
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#fff',
    },
    heading: {
        fontSize: 32,
        marginBottom: 20,
    },
    templateSelector: {
        marginBottom: 20,
        '& select': {
            padding: 8,
            border: '1px solid #ccc',
            borderRadius: 4,
        },
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        gap: 20,
    },
    headerBox: {
        backgroundColor: '#70707010',
        borderRadius: 8,
        // boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        padding: 20,
        width: '48%',
        maxHeight: 'fit-content'
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        padding: 10,
        border: '1px dashed #e57373',
        borderRadius: 4,
    },
    addButton: {
        backgroundColor: '#fff',
        border: '1px dashed #ff7043',
        color: '#ff7043',
        padding: 8,
        width: '100%',
        cursor: 'pointer',
        borderRadius: 4,
        marginTop: 10,
        '&:hover': {
            backgroundColor: '#ffe0b2',
        },
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 10,
        marginTop: 20,
    },
    btnBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '2rem',
        '& .MuiButtonBase-root': {
            // marginRight: '2rem',
            width: '150px',
            fontFamily: "Montserrat",
            fontWeight: 800,
            lineHeight: 0,
            fontSize: "0.6rem",
            borderRadius: "0.2rem",
        }
    },
    btn: {
        width: '150px',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#fff",
        color: "#f4511e",
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        borderRadius: "0.2rem",
        cursor: "pointer",
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
        }
    },
    saveBtn: {
        backgroundColor: "#F4511E",
        // marginRight: "2rem",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F4511E",
        }
    },
    inActiveCard: {
        opacity: 0.4,
        cursor: 'pointer',
    },
}));

export default useStyles;
