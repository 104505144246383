import React, { useState, useRef, useContext, useEffect } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components

//utils
import commonUtil from '../../../../utils/commonUtil';

//constants
// import constants from '../../../../constants';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';
import isEmpty from '../../../../utils/isEmpty';
import CustomButton from '../../../buttons';
import EditableTitle from '../../../learning-aid/components/rtf-title';
import EditableBody from '../../../learning-aid/components/rtf-body';
import SuggestedOrUpload from '../../../learning-aid/components/suggested-or-upload';
import DragDropFiles from './drag-drop-files-uploader';


const VideoCard = ({
  customAspectRatio = true,
  handleSaveCard,
  isEdit,
  editCardData,
  handleCancel,
  handleUpdateCard,
  titleBody,
  setTitleBody,
  setTitleText,
  setBodyText,
  handleTitleClick,
  handleBodyClick,
  toolbar,
  setToolbar,
  laBgImgData,
  handleChangeLaBgImg,
  video,
  setVideo,
  videoFile,
  setVideoFile,
  handleVideoUpload,
  setIsYoutube,
  isYoutube,
  setLink,
  setFileType = ()=>{},
  link,
  handleRemoveVideo,
  handleExternalLink
}) => {
  const classes = useStyles();
  const styles = commonStyles();
  const [globalStates] = useContext(GlobalStates)
  // const [titleBody, setTitleBody] = useState({ title: "", body: "" })
  let capture = useRef();
  let titleRef = useRef(null);
  let bodyRef = useRef(null);

  //   console.log(
  //     // customAspectRatio,
  //     // handleSaveCard,
  //     // isEdit,
  //     // editCardData,
  //     // handleCancel,
  //     handleUpdateCard,
  //     // titleBody,
  //     // setTitleBody,
  //     // handeleTitleChange,
  //     // handleBodyChange,
  //     // handleTitleClick,
  //     // handleBodyClick,
  //     // toolbar,
  //     // setToolbar,
  //     // laBgImgData,
  //     // handleChangeLaBgImg
  //   );

  //   useEffect(() => {
  //     // setToolbar({ title: false, body: false })
  //     if (isEdit && editCardData !== null) {
  //       let card = editCardData.card;
  //       let videoObj = commonUtil.getVideoObjFromContents(card.contents);
  //       // console.log('videoObj = ', videoObj);
  //       if (videoObj.name === 'YOUTUBE') {
  //         // console.log('Its youtube link ');
  //         setIsYoutube(true);
  //         setLink(videoObj.content);
  //         setVideoFile(null);
  //         setVideo('');
  //       } else {
  //         // console.log('Its custom video ');
  //         setIsYoutube(false);
  //         setVideo(videoObj.content);
  //         setVideoFile(videoObj.content);
  //         setLink('')
  //       }
  //       let title = commonUtil.getTitleFromContents(card.contents);
  //       // titleRef.current.innerHTML = commonUtil.removeStyleAndHrefTagsFromString(title);
  //       let body = commonUtil.getBodyFromContents(card.contents);
  //       // bodyRef.current.innerHTML = commonUtil.removeStyleAndHrefTagsFromString(body);
  //       setTitleBody({ title: title, body: body })

  //     } else {
  //       setTitleBody({ title: '', body: '' })
  //     }

  //   }, [editCardData])


  //   const captureScreenshot = async () => {
  //     if (!link.length && !videoFile) {
  //       globalStates.handleToast(true, 'Please enter the URL or upload video', 'error')
  //       return
  //     }
  //     if (!titleBody.title.length) {
  //       globalStates.handleToast(true, 'Please enter the Title', 'error');
  //       return
  //     }
  //     if (!titleBody.body.length) {
  //       globalStates.handleToast(true, 'Please enter the Body', 'error');
  //       return
  //     }


  //     let cardData = {
  //       cardType: 'CREATED',
  //       title: titleBody.title,
  //       body: titleBody.body,
  //       isYoutube: isYoutube,
  //       youtubeUrl: link,
  //       videoFile: videoFile,
  //       thumbImage: constants.VTB_DEFAULT_THUMBNAIL,
  //       thumbFileSize: 0
  //     };
  //     if (!isEmpty(videoFile) && typeof (videoFile) !== "string") {
  //       cardData.thumbFileSize = videoFile.size;
  //     }

  //     const type = 'VTB';

  //     if (isEdit) {
  //       cardData.cardId = editCardData.id;
  //       // cardData.prevImageUrl = prevImageUrl;
  //       handleUpdateCard(type, cardData)
  //       return
  //     }
  //     handleSaveCard(type, cardData);

  //   }


  // console.log(videoFile)


  const handeleTitleChange = (text) => {
    const editor = titleRef.current.getEditor();
    let value = editor.getText().trim();
    setTitleBody(prev => ({ ...prev, title: text }))
    setTitleText(prev => prev = value)
}

const handleBodyChange=(text)=>{
    const editor = bodyRef.current.getEditor();
    let value = editor.getText().trim();
    setTitleBody(prev => ({ ...prev, body: text }))
    setBodyText(prev => prev = value)
}



  const handleSelectVideo = () => {
    // if(file)
    handleVideoUpload();
    setIsYoutube(false);
    // console.log('handleSelectVideo = ', file);
  }

  useEffect(()=>{
    handleSelectVideo()
  },[])





  const fileTypes = ["MP4", "AVI", "MOV", "GIF"];

  //   let textColor = laBgImgData?.bgImg ? '#fff' : 'rgb(0, 0, 0, 0.5)';
  //   let placeholderColor = laBgImgData?.bgImg ? 'rgb(255, 255, 255, 0.7)' : 'rgb(0, 0, 0, 0.2)';

  return (


    <>
      <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}
        style={{
          //   backgroundImage: `url(${laBgImgData.bgImg})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundBlendMode: laBgImgData?.bgImg && 'multiply',
          //   backgroundColor: laBgImgData?.bgImg && 'rgb(0,0,0,0.5)',
        }}
      >
        <div className={classes.videoBox}>
          {
            (!link.length && !videoFile) ?
              <DragDropFiles
                typesAccepted={fileTypes}
                withExternalLink={true}
                handleExternalLink={handleExternalLink}
                handleChange={handleSelectVideo} /> :

              (link.length) ?
                <iframe width="100%" height="100%"
                  src={link}>
                </iframe> :
                <video width="100%" height="100%" controls>
                  <source src={video} />
                </video>
          }

        </div>
        {(link.length > 0 || videoFile !== null || video) ?
          <div className={classes.removeBtnBox}>
            <CustomButton
              btnText={'REMOVE VIDEO'}
              bgcolor={'#F4511E'}
              textColor={'#FFF'}
              fw={800}
              ls={1}
              handleClick={handleRemoveVideo}
            />
          </div> : null}

        {/* <div className={styles.titleContainer}>
          <EditableTitle
            ref={titleRef}
            minHeight={0}
            fs={'1.6rem'}
            handeleTitleChange={handeleTitleChange}
            textColor={textColor}
            placeholderColor={placeholderColor}
          />
        </div> */}
        {/* <EditableBody
          ref={bodyRef}
          handleBodyChange={handleBodyChange}
        /> */}
        <EditableTitle
          ref={titleRef}
          minHeight={'3rem'}
          maxHeight={'6rem'}
          handeleTitleChange={handeleTitleChange}
          value={titleBody.title}
        //   handleTitleClick={handleTitleClick}
        //   showTitleToolbar={toolbar.title}
        />
        <EditableBody
          ref={bodyRef}
          minHeight={'10rem'}
          maxHeight={'10rem'}
          handleBodyChange={handleBodyChange}
          value={titleBody.body}
        //   handleBodyClick={handleBodyClick}
        //   showBodyToolbar={toolbar.body}
        //   textColor={textColor}
        //   placeholderColor={placeholderColor}
        />
        {/* {true ?
          <div className={styles.suggestionBox}>
            <SuggestedOrUpload
              title={'Background Image:'}
            //   handleChange={handleChangeLaBgImg}
            />
          </div> : null} */}
      </div>

      {/* {true && <div className={styles.btnBox}>
        <CustomButton
          btnText={'SAVE'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={captureScreenshot.bind(this)}
        // handleClick={() => setCustomAspectRatio(!customAspectRatio)}
        />
        {isEdit && <CustomButton
          btnText={'CANCEL'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={handleCancel}
        />}
      </div>} */}
    </>
  )
}

export default VideoCard;


const useStyles = makeStyles(() => ({
  videoBox: {
    borderRadius: '4px',
    minHeight: '50%',
    // height: '50%',
    width: '100%',
    maxWidth: 'calc(420px - 1rem)',
    aspectRatio: 4 / 3
  },
  removeBtnBox: {
    // border: '1px solid',
    height: '2.6rem',
    width: '9rem',
    position: 'absolute',
    top: '4rem',
    right: '-9rem',
    '& .MuiButtonBase-root': {
      '&:hover': {
        borderColor: '#f4511e',
        backgroundColor: '#fff',
        color: '#f4511e'
      }
    },

  },

  titleContainer: {
    // border : '1px solid',
    width: '100%',
    height: '8rem',
    overflow: 'hidden',
    margin: '0.8rem 0 !important',
    '& > div': {
      margin: '0 !important',
      maxHeight: '100%',
      height: '100%',
      '&:hover': {
        border: 'none',
      }
    },
    '&:hover': {
      border: '1px dashed rgb(204, 204, 204, 0.5)',
    }
  },


}));
