import React, { useState } from 'react'

//components
import SearchBox from '../../../search-box';
import CustomSelect from '../../../custom-styled/custom-select';

//styles
import useStyles from '../../index.styles';
import commonUtil from '../../../../utils/commonUtil';
import Title from '../../../title';
import CustomInput from '../../../custom-styled/custom-input';

const Step1 = ({
    userType,
    title,
    setTitle,
    message,
    setMessage,
    setStep2,
    setStep3,
    setStep4,
    companies,
    companiesObject,
    selectedCompany,
    setSelectedCompany,
    _selectCompany,
    setLaunchType,
    setFileUpload,
    launching,
    globalStates

}) => {
    const classes = useStyles();

    const handleTitleChange = (e) => {
        let value = e.target.value;
        setTitle(value);
        if (!value.length) {
            setStep2(false);
            setStep3(false);
            setStep4(false);
            setLaunchType([]);
            setFileUpload(null);
            return;
        }
        if (message.length) setStep2(true);
    }

    const handleMessageChange = (e) => {
        let value = e.target.value;
        setMessage(value);
        if (!value.length) {
            setStep2(false);
            setStep3(false);
            setStep4(false);
            setLaunchType([]);
            setFileUpload(null);
            return;
        }
        if (title.length) setStep2(true);
    }




    return (
        <>
            {userType === "superAdmin" &&
                <div className={classes.row} style={{ gap: '0.6rem', margin: '0.5rem 0'}}>
                    <Title titleText={"Company"}/>
                    <CustomSelect
                        defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Company', selected: selectedCompany, dataObject: companiesObject })}
                        autoClose={true}
                        withSearch={true}
                        withSelectAllOption={false}
                        listData={companies}
                        selected={selectedCompany}
                        handleSelect={_selectCompany}
                    />
                </div>
            }
            <h2 style={{ fontSize: '1.2rem', fontFamily: "Montserrat", color: "#707070", padding: '1rem 0rem' }}>Step 1: Content</h2>


            <div className={classes.titleWrapper}>
                <Title titleText={"Title"} />
                <CustomInput
                    id={"title"}
                    withLimitWarning={false}
                    value={title}
                    placeholder='Notification Title Here'
                    handleChange={handleTitleChange}
                    wrapperWidth='50rem'
                />

            </div>
            <div className={classes.messageWrapper}>
                <Title titleText={"Message"} />
                <textarea
                    value={message}
                    placeholder='Notification Description Here'
                    onChange={handleMessageChange}
                />

            </div>
        </>
    )
}

export default Step1;

