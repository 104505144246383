import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import AudioFileIcon from '@mui/icons-material/AudioFile';

const AudioUploadContainer = ({
    height = '100%',
    width = '100%',
    handleChange,
    accepted = ['MP3', 'WAV', 'OGG', 'M4A'],
    textColor = '#fff',
    iconColor = '#fff'
}) => {
    const iconFilter = (iconColor === '#f4511e') ? 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(-36deg) brightness(99%) contrast(93%)' : '';
    const classes = useStyles({ height, width, textColor, iconFilter });

    return (
        <Button disabled='true' variant="outlined" component="label" className={classes.wrapper} onChange={handleChange}>
            {/* <img src='/images/learning-aid-assets/add.svg' alt='' /> */}
            <AudioFileIcon sx={{fontSize:"5rem"}}/>
            {/* <h5>UPLOAD AUDIO</h5> */}
            <h6>Supported types: {accepted.join(', ')} </h6>
            {/* <input hidden accept="audio/*" type="file" /> */}
        </Button>
    );
}

export default AudioUploadContainer;

const useStyles = makeStyles(() => ({
    wrapper: {
        borderRadius: '4px',
        height: ({ height }) => height,
        width: ({ width }) => width,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#f4511e',
        '& > img': {
            height: '3rem',
            width: '3rem',
            filter: ({ iconFilter }) => iconFilter,
        },
        '& h5': {
            color: ({ textColor }) => textColor,
            fontSize: '0.8rem',
            fontWeight: 800,
            fontFamily: 'Montserrat'
        },
        '& h6': {
            color: ({ textColor }) => textColor,
            fontSize: '0.6rem',
            fontWeight: 800,
            fontFamily: 'Montserrat'
        },
        border: '2px dashed rgb(204, 204, 204, 0.5)',
        borderColor: 'rgb(204, 204, 204, 0.5) !important',
        '&:hover': {
            border: 'none',
        }
    },
}));
