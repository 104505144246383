import React, { useContext, useState } from 'react';


//components
import lotties from '../../lotties';
import CustomLottie from '../learning-aid/components/custom-lottie';

//services
import bulletinServices from '../../services/bulletin'


//global-states
import { GlobalStates } from '../../App'

//styles
import useStyles from './index.styles'
import constants from '../../constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BulletinCard = ({ data, companyId, index ,getAllBulletins}) => {


    const styles = useStyles();
    const history = useHistory();
    const [globalStates] = useContext(GlobalStates);
    const [show, setShow] = useState(false)//false
    const handleMouseEnter = () => {
        // console.log("mouse in");
        // if (copyMsId === data.id) return;
        setShow(true)
    }
    const handleMouseLeave = () => {
        // console.log("mouse out");
        setShow(false)//false
    }
   if(data?.content.contents[0].name==="YOUTUBE")
    // console.log("thumbnail youtube ",data?.content.contents[0].thumbnail,data?.title);
console.log(index,data)

    const handleBulletinClick = (e) => {
        return
        history.push(`/bulletin?isEdit=true&c_id=${companyId}&id=${data.id}`)


    }

    const handleCopy = async (e) => {
        console.log('I am called');
        e.stopPropagation()

        const response =  await bulletinServices.bulletinCopy({
            companyId: companyId,
            bulletInId: data.id
        });
            if(response.status === 200 || response.status === 201){
                globalStates.handleToast(true, 'Bulletin copied succesfully.', 'success');
                console.log(response.data.data)
                // setBulletinsData(response.data.data)
                getAllBulletins()
            }
            else{
                globalStates.handleToast(true, 'Internal Server Error.', 'error');
            }
        
    }

    const handleDelete = async (e) => {
        console.log('I am called');
        e.stopPropagation()

        const response =  await bulletinServices.bulletinDelete({
            companyId: companyId,
            bulletInId: data.id
        });
            if(response.status === 200 || response.status === 201){
                globalStates.handleToast(true, 'Bulletin deleted succesfully.', 'success');
                console.log(response.data.data)
                // setBulletinsData(response.data.data)
                getAllBulletins()
            }
            else{
                globalStates.handleToast(true, 'Internal Server Error.', 'error');
            }
        
    }
    // const shadows = ['rgb(104 0 0 / 75%)', 'rgb(0 36 104 / 75%)', 'rgb(104 0 98 / 69%)', 'rgb(51 104 0 / 69%)']
    // const getRandomShadow = () => {
    //   let randomIndex = Math.floor(Math.random() * shadows.length);
    //   return shadows[randomIndex]
    // }

    return (
        <>
            <div
                // key={key}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={styles.card}
                onClick={(e) => { handleBulletinClick(e) }}
            >
                <img
                    // src={data?.content.contents[0].content || constants.MS_DEFAULT_COVER_IMAGE}
                    src={data?.content.contents[0].thumbnail || constants.MS_DEFAULT_COVER_IMAGE}
                    alt=''
                // style={{ filter: (copyMsId === data.id) ? 'blur(7px)' : '' }}
                />

                {
                    // (copyMsId === data.id) ?
                    // <div className={styles.lottieBox}>
                    //     <CustomLottie animationData={lotties.copyMicroskill} />
                    //     <h5>Copied Successfully!</h5>
                    // </div> 
                    // : null
                }
                <div className={styles.cardRow1}
                    style={{
                        // filter: (copyMsId === data.id) ? 'blur(7px)' : ''
                    }}>
                    {/* {show ?
                        <span onClick={() => handleViewGroups(data)}>
                            View groups
                        </span> : null
                    } */}
                    {show ?
                    <span className={styles.btnBox}>
                        <button className={styles.editBtn} onClick={handleCopy}>
                            <img src="/images/icons/duplicate.svg" alt="Copy" title="Duplicate" />
                        </button>
                        {/* <button className={styles.editBtn} >
                                <img src="/images/icons/duplicate.svg" alt="Copy" title="Duplicate" />
                            </button> */}
                        <button className={styles.editBtn} onClick={handleDelete}>
                            <img src="/images/icons/delete-photo.svg" alt="Delete" title="Delete" />
                        </button>
                    </span>
                     : null} 

                </div>

                <div className={styles.cardRow2} style={{
                    // filter: (copyMsId === data.id) ? 'blur(7px)' : ''
                }}>
                    <h1>{data?.title}</h1>
                    {/* <span>{data.content.contents[2].content}</span> */}
                    <div className={styles.viewsLikes}>
                        <span>
                            {/* <img src="/images/add-microskill/eye-solid.svg" alt='' /> */}
                            {/* {data.views} */}
                        </span>
                        {/* <span>
                            <img src="/images/add-microskill/heart-solid.svg" alt='' />
                            {data.likes}
                        </span> */}
                    </div>
                    {/* <img
                        // src={brandIconPath}
                        src={data.brandImage || constants.MS_DEFAULT_BRAND_ICON}
                        className={styles.brandIcon}
                        alt="Brand Icon"
                    /> */}
                </div>

            </div>
        </>
    )
}

export default BulletinCard