import React, { useState, useEffect, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
// import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import EmailReports from '../email-report';
import { GlobalStates } from '../../../../App';
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';
import reportsServices from '../../../../services/reports';
import useStyles from './index.styles';
import isEmpty from '../../../../utils/isEmpty';

// const reportData = [
//     { name: 'Regional Completions', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'North Heads Comparison', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'North Dealers KPIs', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'All Dealership KPIs', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'West Completions', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'East Pulsar NS400', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'All North Dealers', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'BAL-MC Master', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'KPI Tracker', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'Tough questions Q2 2024', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
// ];

const ReportTable = ({ setBackdrop, mapping }) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [emailId, setEmailId] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [globalStates] = useContext(GlobalStates);
    const query = useQuery();
    const companyId = query.get("c_id");
    // const [payload, setPayload] = useState({ companyId: companyId, flag: "filter" });
    let mappingObject = commonUtil.objectify(mapping, "name");


    useEffect(() => {
        (async () => {
            const response = await reportsServices.fetchSavedCustomTemplates(companyId);
            console.log('reponse', response);
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                setData(data);
            }

        })()
    }, [])

    const handleDownloadCustomReport = async (id) => {
        setBackdrop(true);
        const downloadReportPayload = {
            templateId: id,
            flag: "excel",
            companyId: companyId,
        }
        const response = await reportsServices.sendReportActions(downloadReportPayload);
        console.log('downloadAsExcel response = ', response);
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location;
            console.log('downloadAsExcel fileUrl = ', fileUrl);
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Login reports'; // Set the desired filename for the downloaded file
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }

    const handleEmailCustomReport = async (id) => {
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        setSendingEmail(true);
        const emailReportPayload = {
            templateId: id,
            flag: "email",
            email: emailId,
            companyId: companyId,
        }
        const response = await reportsServices.sendReportActions(emailReportPayload);
        console.log('emailReport response = ', response);
        if (response.status === 200) {
            let message = `Login Report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
            setSendingEmail(false);
            setEmailDialog(false);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setSendingEmail(false);
        }
    }

    const handleDeleteTemplate = async (id) => {
        let confirm = window.confirm("Do you really want to delete this template?");
        if (!confirm) return
        globalStates.handleProgressDialog(true, 'Please wait...')
        const response = await reportsServices.deleteSavedCustomTemplate({ id });
        globalStates.handleProgressDialog(false)
        if (isEmpty(response)) {
            globalStates.handleToast(true, "Request timeout", "error");
            return
        }
        if (response.status === 200 || response.status === 201) {
            const filterData = data.filter((ele) => ele.id !== id)
            setData(filterData);
            globalStates.handleToast(true, 'Deleted succesfully!', "success");

        } else {
            globalStates.handleToast(true, "Failed to delete, please try again!", "error");
        }

    }

    return (
        <div className={classes.container}>
            <h1 className={classes.heading}>All Report Templates</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell
                            >
                                Template Name
                            </TableCell>
                            <TableCell
                            >
                                Created On
                            </TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 && data.map((row, index) => (
                            <TableRow key={index} className={classes.tableRow}>
                                <TableCell>{row.title}</TableCell>
                                <TableCell>{`${commonUtil.formatDate(row.createdOn)}`}</TableCell>
                                <TableCell align="center">
                                    <div className={classes.actionIcons}>
                                        <EmailReports
                                            open={emailDialog}
                                            setOpen={setEmailDialog}
                                            loading={sendingEmail}
                                            handleChange={(e) => setEmailId(e.target.value)}
                                            handleClick={() => handleEmailCustomReport(row.id)}
                                        />
                                        <img src='/images/analytics/download-icon.svg' alt='' title='Download Login Report' onClick={() => handleDownloadCustomReport(row.id)} style={{ cursor: 'pointer' }} />
                                        <IconButton className={classes.iconButton} onClick={() => handleDeleteTemplate(row.id)}>
                                            <DeleteOutlineRoundedIcon />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ReportTable;