import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '../table';
import { GlobalStates } from "../../../../App";
import commonUtil from '../../../../utils/commonUtil';
import Label from '../../../custom-styled/label';
// import CustomReportTable from '../custom-report-table';
import reportsServices from '../../../../services/reports';
import {
    Box,
    Button, Typography, Modal, TextField, Stack
} from '@mui/material';

// Custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
    },
    header: {
        marginBottom: '16px',
    },
    buttonGroup: {
        '& button': {
            marginRight: '8px',
        },
    },
    tableHeader: {
        backgroundColor: '#ffe8e8',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '16px',
    },
    dateRange: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
    },
    tableCell: {
        fontWeight: 'bold',
    },
    heading: {
        fontSize: 32,
        marginBottom: 20,
    },
    btn: {
        width: '150px',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#fff",
        color: "#f4511e",
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        borderRadius: "0.2rem",
        cursor: "pointer",
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
        }
    },
}));

const defaultHeadings = [
    { title: "Learner Name", key: "Learner Name" },
    { title: "Email ID", key: "Email" },
    { title: "Username", key: "Username" },
    { title: "First Name", key: "First Name" },
    { title: "Last Name", key: "Last Name" },
    { title: "Mobile", key: "Mobile" },
    { title: "Designation", key: "Designation" },
    { title: "Zone", key: "Zone" },
    { title: "Supervisor", key: "Supervisor" },
    { title: "Business Group", key: "Business Group" },
    // { title: "Password", key: "Password" },
    { title: "LoginStatus", key: "LoginStatus" },
    { title: "First Login", key: "firstLogin", isDate: true },
    { title: "Latest Login", key: "latestLogin", isDate: true },
];

let defaultPagination = {
    limit: 1,
    pageCount: 0,
    startIndex: -1,
    totalCount: 0
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const CustomReportTable = ({ payload }) => {
    const classes = useStyles();
    const [mapping, setMapping] = useState([]);
    let mappingObject = commonUtil.objectify(mapping, "name");
    const [tableLoader, setTableLoader] = useState(false);
    const [showGenerate, setShowGenerate] = useState(true);
    const [progress, setProgress] = useState(0);
    const [headings, setHeadings] = useState(defaultHeadings);
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState(defaultPagination);
    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(50);
    const [intervalCleared, setIntervalCleared] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const intervalRef = useRef();
    const [pageCount, setPageCount] = useState(0);
    const [detailedReport, setDetailedReport] = useState(true);
    let myBookName = detailedReport ? "Login Details" : "Login Summary";
    const [globalStates] = useContext(GlobalStates);
    const [largeReport, setLargeReport] = useState(false);
    const [open, setOpen] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [reportOn, setReportOn] = useState('');
    const [emailId, setEmailId] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSaveTemplate = async () => {
        if (!templateName.length) {
            globalStates.handleToast(true, 'Please enter the template name', 'error')
            return
        }
        if (reportOn === 'email' && !emailId.length) {
            globalStates.handleToast(true, 'Please enter the email Id', 'error')
            return
        }
        const payloadData = { ...payload, title: templateName }
        globalStates.handleProgressDialog(true, 'Please wait...')
        const response = await reportsServices.customReportSaveTemplate(payloadData);
        if (response.status === 201) {
            if (reportOn.length > 0) {
                reportOn === 'download' ? handleDownloadCustomReport(response.data.data.id) : await handleEmailCustomReport(response.data.data.id)
            }
            globalStates.handleProgressDialog(false)
            handleClose();
        }
    };


    useEffect(async () => {
        setTableLoader(true);
        const response = await reportsServices.generateReportTable(payload);
        if (response && response.status === 200) {
            setTableData(response.data.data);
            const headings = commonUtil.getHeadings(response.data.data, 'dateOnly');
            if (headings.length) setHeadings(headings);
            console.log('fetchLoginReports response = ', response);
            updateProgress();

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            return
        }

    }, [])

    useEffect(() => {
        if (intervalCleared) {
            setProgress(0);
            setTableLoader(false);
            setIntervalCleared(false);
        }

    }, [intervalCleared]);



    const updateProgress = () => {
        intervalRef.current = setInterval(() => {
            // console.log('setInterval calling', intervalRef.current);
            setProgress((prevProgress) => {
                console.log('prevProgress in setInterval = ', prevProgress);
                let random = prevProgress + Math.floor(Math.random() * 20);
                if (prevProgress >= 100 || random >= 100) {
                    clearInterval(intervalRef.current);
                    setIntervalCleared(true);
                    return 100
                } else {
                    return random
                }
            });
        }, 300);

    };
    const handleBackClick = () => {
        if (showGenerate) return
        if (startIndex >= limit) {
            setStartIndex(startIndex - limit)
            setPageNo(pageNo - 1);
        }
    }

    const handleForwardClick = () => {
        if (showGenerate) return
        if (startIndex + limit < totalCount) {
            setStartIndex(startIndex + limit);
            setPageNo(pageNo + 1);
        }
    }
    const handleChangeLimit = (event) => {
        setStartIndex(0)
        setPageNo(1);
        setLimit(event.target.value);
    };
    const handleDownloadCustomReport = async (id) => {
        const downloadReportPayload = {
            templateId: id,
            flag: "excel",
            companyId: payload.companyId,
        }
        const response = await reportsServices.sendReportActions(downloadReportPayload);
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Login reports'; // Set the desired filename for the downloaded file
                link.click();
            }


        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    }

    const handleEmailCustomReport = async (id) => {
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        const emailReportPayload = {
            templateId: id,
            flag: "email",
            email: emailId,
            companyId: payload.companyId,
        }
        const response = await reportsServices.sendReportActions(emailReportPayload);
        if (response.status === 200) {
            let message = `Login Report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <h1 className={classes.heading}>Report Summary</h1>
                <Box className={classes.buttonGroup}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setReportOn('email');
                            handleOpen();
                        }}
                        style={{ color: '#fff', backgroundColor: '#ff5722' }}
                        className={classes.btn}
                    >
                        Send On Email
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setReportOn('download');
                            handleOpen();
                        }}
                        style={{ backgroundColor: '#fff' }}
                        className={classes.btn}
                    >
                        Download
                    </Button>
                </Box>
            </Box>

            {/* Table Section */}
            <Table
                loader={tableLoader}
                showGenerate={false}
                handleGenerate={() => setShowGenerate(false)}
                progress={progress}
                tableHeadings={headings}
                tableData={tableData}
                handleForwardClick={handleForwardClick}
                handleBackClick={handleBackClick}
                rows={limit}
                handleChangeNumberOfRows={handleChangeLimit}
                largeReport={largeReport}
                withPagination={false}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Label labelText={'Save Template'} fs='1rem' style={{ marginBottom: '10px' }} />
                    <Stack spacing={2} sx={{ margin: '1rem 0' }}>
                        <TextField
                            label="Template Name"
                            variant="outlined"
                            fullWidth
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                        {reportOn === "email" && <TextField
                            label="Email Id"
                            variant="outlined"
                            fullWidth
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                        />
                        }

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveTemplate}
                            style={{ backgroundColor: '#ff5722' }}
                        >
                            Submit
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </Box >
    );
};

export default CustomReportTable;
