import React, { useState, useEffect } from 'react';

//mui
import AddIcon from '@mui/icons-material/Add';

//utils
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';


// import ViaDemographic from '../../../zw-user-launch/components/via-demographic';
import CustomSelect from '../../../custom-styled/custom-select';

//services
import reportsServices from '../../../../services/reports';



import {
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    IconButton,
    TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useStyles from './index.styles';
import Label from '../../../custom-styled/label';


const demographicsOptions = ['Region', 'Cluster', 'Cluster Head', 'Team Leader', 'TL Email ID'];
const filters = ['North', 'East', 'West', 'South'];


const RowHeadersUI = ({ demographicFilter, setDemographicFilter, mapping, setMapping }) => {
    // const [selectedDemographic, setSelectedDemographic] = useState('');
    // const [selectedFilters, setSelectedFilters] = useState(['West']);
    // const [searchTerm, setSearchTerm] = useState('');
    const query = useQuery();
    const companyId = query.get('c_id');
    // const [demographicFilter, setDemographicFilter] = useState([]);
    const [allDemographicObjects, setAllDemographicObjects] = useState(null);
    const [columnsData, setColumnsData] = useState([]);
    const [selectedDemographicColumns, setSelectedDemographicColumns] = useState([]);
    const classes = useStyles();

    const columnsDataObject = commonUtil.objectify(columnsData, 'id')

    useEffect(() => {
        fetchDemographics(companyId);
    }, [])

    const fetchDemographics = async (companyId) => {
        //  setLoading(true);
        const response = await reportsServices.getDemographics(companyId, true);
        if (response && response.status === 200) {
            let distinctColumnValues = response.data.data.distinctColumnValues;
            updateToDefaultDF(distinctColumnValues);
            let resData = response.data.data;
            if (resData?.mapping && !mapping.length) {
                let mapping = resData?.mapping;
                mapping.forEach(element => {
                    element.id = element.table_column;
                    element.name = element.excel_column;
                });
                setMapping(mapping);
            }
        }
    }
    const updateToDefaultDF = (distinctColumnValues) => {
        if (distinctColumnValues.length) {
            let tempColumnsData = []
            let tempDemographicFilters = [];
            distinctColumnValues.forEach(ele => {
                let tempSubmenu = [];
                ele.values.forEach(item => {
                    if (item) tempSubmenu.push({ id: item, name: item })
                });
                let tempObj = {
                    id: ele.columnName,
                    name: ele.columnName,
                    subMenu: tempSubmenu,
                    selected: [],
                    total: tempSubmenu.length
                };
                tempDemographicFilters.push(tempObj);
                tempColumnsData.push({
                    id: ele.columnName,
                    name: ele.columnName,
                });

            })
            setColumnsData(tempColumnsData);
            setDemographicFilter(tempDemographicFilters);
        }
    }
    // Handle demographic selection
    const handleDemographicChange = (event) => {
        // setSelectedDemographic(event.target.value);
    };

    // Handle filter selection
    const handleFilterChange = (filter) => {
        // setSelectedFilters((prev) =>
        //     prev.includes(filter) ? prev.filter((item) => item !== filter) : [...prev, filter]
        // );
    };

    // Handle search input
    const handleSearchChange = (event) => {
        // setSearchTerm(event.target.value);
    };

    // const filteredFilters = filters.filter((filter) =>
    //     filter.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const selectDemographicColumn = (item) => {
        if (selectedDemographicColumns.includes(item.id)) {
            let filtered = selectedDemographicColumns.filter(ele => ele !== item.id);
            setSelectedDemographicColumns(filtered);
            const newDemographicFilter = demographicFilter.map(curr => {
                let temp = { ...curr };
                if (temp.id === item.id) temp.selected = [];
                return temp
            })
            setDemographicFilter(newDemographicFilter);
        } else {
            setSelectedDemographicColumns([...selectedDemographicColumns, item.id])
        }
    }

    const _selectAllSubmenuDF = (currentList) => {
        let isSelectAll = currentList.selected.length === currentList.subMenu.length;

        let tempDemographicFilter = demographicFilter.map((element) => {
            let tempElement = { ...element };
            if (element.id === currentList.id) {
                let newSelected = []
                if (!isSelectAll) {
                    newSelected = currentList.subMenu.map(ele => ele.id)
                }

                tempElement = { ...element, selected: newSelected };

            }
            return tempElement
        });
        setDemographicFilter(tempDemographicFilter);
    }

    const handleSelectDemographic = (ele, item) => {
        let tempDemographicFilter = demographicFilter.map((element) => {
            let tempElement = { ...element };
            if (element.id === ele.id) {
                let newSelected = element.selected;
                if (newSelected.includes(item.id)) {
                    let filtered = newSelected.filter(curr => curr !== item.id);
                    newSelected = filtered;
                } else {
                    newSelected = [...newSelected, item.id]
                }
                tempElement = { ...element, selected: newSelected };

            }
            return tempElement
        });
        setDemographicFilter(tempDemographicFilter);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

            {/* {demographicsOptions.map((option) => (
                <FormControl fullWidth key={option}>
                    <InputLabel>{option}</InputLabel>
                    <Select value={selectedDemographic} onChange={handleDemographicChange}>
                        {demographicsOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ))}

            <button
            // className={classes.addButton}
            // onClick={addRowHeader}
            >
                Add Demographics +
            </button> */}
            {
                selectedDemographicColumns.length ?
                    <div className={classes.selectMenusWrapper}>

                        {
                            demographicFilter.map((ele, i) => {
                                return selectedDemographicColumns.includes(ele.id) ? (
                                    <>
                                        <Label labelText={ele.name} fs='0.8rem'/>
                                        <CustomSelect
                                            defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: `Select ${ele.name}`, selected: ele.selected, dataObject: commonUtil.objectify(ele.subMenu, 'id') })}
                                            autoClose={false}
                                            withSearch={true}
                                            withSelectAllOption={true}
                                            listData={ele.subMenu}
                                            selected={ele.selected}
                                            handleSelect={(item) => handleSelectDemographic(ele, item)}
                                            customSelectAll={true}
                                            _selectAll={() => _selectAllSubmenuDF(ele)}
                                        />
                                    </>

                                ) : null
                            })
                        }
                    </div>
                    : null
            }

            <div className={classes.addContentBtn}>
                <AddIcon /> Add Demographic
                <CustomSelect
                    hideBaseBtn={true}
                    defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Demographic', selected: selectedDemographicColumns, dataObject: columnsDataObject })}
                    withSelectAllOption={false}
                    listData={columnsData}
                    selected={selectedDemographicColumns}
                    handleSelect={selectDemographicColumn}
                />
            </div>



            {/* <Box sx={{ mt: 3 }}>
                <Typography variant="h6">Filter</Typography>
                <TextField
                    fullWidth
                    placeholder="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                />

                <List>
                    <ListItem>
                        <Checkbox
                            checked={selectedFilters.length === filters.length}
                            onChange={() =>
                                setSelectedFilters(
                                    selectedFilters.length === filters.length ? [] : filters
                                )
                            }
                        />
                        <ListItemText primary="Select All" />
                    </ListItem>

                    {filteredFilters.map((filter) => (
                        <ListItem key={filter}>
                            <Checkbox
                                checked={selectedFilters.includes(filter)}
                                onChange={() => handleFilterChange(filter)}
                            />
                            <ListItemText primary={filter} />
                        </ListItem>
                    ))}
                </List>

                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                    Add
                </Button>
                <Button variant="outlined" color="secondary">
                    Clear Selection
                </Button>
            </Box> */}

        </Box>
    );
};

export default RowHeadersUI;