import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '2rem auto',
        width: '80%',
    },
    tableHeader: {
        backgroundColor: '#f5f5f5',
    },
    tableRow: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
    },
    actionIcons: {
        display: 'flex',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    iconButton: {
        cursor: 'pointer',
        background: '#f4511e',
        color: '#fff',
        height: '34px',
        width: '34px',
        '&:hover': {
            backgroundColor: '#f4511e'
        },
    },
    sortableHeader: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    heading: {
        fontSize: 32,
        marginBottom: 20,
    },

}));

export default useStyles;