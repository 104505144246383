import React, { useState, useEffect, useRef, useContext } from 'react'
import html2canvas from "html2canvas";

//mui
import { makeStyles } from "@mui/styles";

//components
import CustomButton from '../../../buttons';
// import EditableTitle from '../editable-title';
// import EditableBody from '../editable-body';

//constants
import constants from '../../../../constants';

//utils
import commonUtil from '../../../../utils/commonUtil';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';
import EditableTitle from '../../../learning-aid/components/rtf-title';
import EditableBody from '../../../learning-aid/components/rtf-body';
import SuggestedOrUpload from '../../../learning-aid/components/suggested-or-upload';
import UploadContainer from './upload-container';

const ImageCard = ({
    suggestedImages,
    customAspectRatio = true,
    handleSaveCard,
    isEdit,
    editCardData,
    handleCancel,
    handleUpdateCard,
    titleBody,
    setTitleBody,
    setTitleText,
    setBodyText,
    laBgImgData,
    handleChangeLaBgImg,
    handleTitleClick,
    handleBodyClick,
    toolbar,
    setToolbar,
    image,
    setImage,
    imageFile,
    setImageFile,
    hanldeFileUpload
}) => {
    const classes = useStyles();
    const styles = commonStyles();
    const [globalStates] = useContext(GlobalStates);
    //   const [image, setImage] = useState(null);
    const [prevImageUrl, setPrevImageUrl] = useState(null);
    // const [image, setImage] = useState(constants.SUGGESTED_IMAGES[0].url);
    // const [imageFile, setImageFile] = useState(null);
    const [canvas, setCanvas] = useState(null);
    const [isSuggested, setIsSuggested] = useState(true);
    // const [customAspectRatio, setCustomAspectRatio] = useState(true);//toggle aspect ratio
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
    let capture = useRef();
    let titleRef = useRef(null);
    let bodyRef = useRef(null);

    //   useEffect(() => {
    //     setToolbar({ title: false, body: false })
    //     if (isEdit && editCardData !== null) {
    //       let card = editCardData.card;
    //       let imgUrl = commonUtil.getImageUrlFromContents(card.contents);
    //       // console.log('url = ', imgUrl);
    //       setPrevImageUrl(imgUrl);
    //       globalStates.handleProgressDialog(true, 'Loading...');
    //       commonUtil.imageUrlToBase64(imgUrl, (base64) => {
    //         setImage(base64);
    //         globalStates.handleProgressDialog(false);
    //       })
    //       setIsSuggested(false);
    //       let title = commonUtil.getTitleFromContents(card.contents);
    //       let body = commonUtil.getBodyFromContents(card.contents);
    //       setTitleBody({ title: title, body: body })

    //     } else {
    //       setTitleBody({ title: '', body: '' })
    //     }
    //   }, [editCardData])



    //   const captureScreenshot = async () => {
    //     if (!image) {
    //       globalStates.handleToast(true, 'Please upload the image', 'error')
    //       return
    //     }
    //     if (!titleBody.title.length) {
    //       globalStates.handleToast(true, 'Please enter the Title', 'error');
    //       return
    //     }
    //     if (!titleBody.body.length) {
    //       globalStates.handleToast(true, 'Please enter the Body text', 'error');
    //       return
    //     }

    //     if (laBgImgData?.bgImg) {
    //       var backgroundColorDiv = document.createElement("div");
    //       // Set the background color on the additional div
    //       backgroundColorDiv.style.backgroundColor = 'rgba(0,0,0,0.5)';
    //       backgroundColorDiv.style.position = 'absolute';
    //       backgroundColorDiv.style.top = '0';
    //       backgroundColorDiv.style.left = '0';
    //       backgroundColorDiv.style.width = '100%';
    //       backgroundColorDiv.style.height = '100%';
    //       backgroundColorDiv.style.zIndex = -1;

    //       // Append the additional div to the container
    //       capture.current.appendChild(backgroundColorDiv);
    //     }

    //     // setCustomAspectRatio(!customAspectRatio)
    //     const canvas = await html2canvas(capture.current);
    //     if (laBgImgData?.bgImg) capture.current.removeChild(backgroundColorDiv);

    //     //CONVERTING CANVAS TO BASE64
    //     const imgData = canvas.toDataURL("image/png");
    //     // // console.log("canvas to base64 = ", imgData);
    //     setCanvas(imgData)
    //     const myFile = commonUtil.base64toFile(imgData)

    //     let cardData = {
    //       id: 0,
    //       cardType: "CREATED",
    //       ratio: "4:3",
    //       title: titleBody.title,
    //       body: titleBody.body,
    //       thumbImageFile: myFile,
    //       thumbFileSize: myFile.size,
    //       imageFile: imageFile,
    //       suggestedImgUrl: image,
    //       isSuggested: isSuggested,
    //     }

    //     const type = 'ITB'
    //     const withImage = true;

    //     if (isEdit) {
    //       cardData.cardId = editCardData.id;
    //       cardData.prevImageUrl = prevImageUrl;
    //       handleUpdateCard(type, cardData, withImage, isSuggested)
    //       return
    //     }

    //     handleSaveCard(type, cardData, withImage, isSuggested);
    //     return
    //   }


    //   const onImageChange = (event) => {
    //     console.log("Image Uplaod",event);
    //     if (event.target.files && event.target.files[0]) {
    //       setImageFile(event.target.files[0]);
    //       let img = URL.createObjectURL(event.target.files[0]);
    //       setIsSuggested(false);
    //       setImage(img);
    //     }
    //   };


    const handeleTitleChange = (text) => {
        const editor = titleRef.current.getEditor();
        let value = editor.getText().trim();
        setTitleBody(prev => ({ ...prev, title: text }))
        setTitleText(prev => prev = value)
    }

    const handleBodyChange=(text)=>{
        const editor = bodyRef.current.getEditor();
        let value = editor.getText().trim();
        setTitleBody(prev => ({ ...prev, body: text }))
        setBodyText(prev => prev = value)
    }


    

    const handleMouseOver = () => {
        setShowDeleteIcon(true);
    }
    const handleMouseLeave = () => {
        if (image) setShowDeleteIcon(false);
    }
    const handleRemoveImage = () => {
        setImageFile(null);
        setImage(null)
        setShowDeleteIcon(false);
    }
    let textColor = 'rgb(0, 0, 0, 0.5)';
    let placeholderColor = 'rgb(0, 0, 0, 0.2)';

    return (


        <>
            <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}
                style={{
                    //   backgroundImage: `url(${laBgImgData.bgImg})`,
                    //   backgroundSize: 'cover',
                    //   backgroundPosition: 'center',
                    //   backgroundBlendMode: laBgImgData?.bgImg && 'multiply',
                    //   backgroundColor: laBgImgData?.bgImg && 'rgb(0,0,0,0.5)',
                }}
                onMouseLeave={handleMouseLeave}
            >
                <div className={classes.imgBox} >
                    {showDeleteIcon ?
                        <span className={classes.deleteIcon}>
                            <img src="/images/icons/delete-photo.svg" alt="Delete Button" title="Remove Image" onClick={handleRemoveImage} />
                        </span>
                        : null}
                    {
                        image ?
                            <img src={image} alt='' onMouseOver={handleMouseOver} /> :
                            <UploadContainer handleChange={hanldeFileUpload} textColor={textColor} iconColor={laBgImgData?.bgImg ? '#fff' : '#f4511e'} />
                    }
                </div>
                <EditableTitle
                    ref={titleRef}
                    minHeight={'3rem'}
                    maxHeight={'6rem'}
                    handeleTitleChange={handeleTitleChange}
                    //   textColor={textColor}
                    //   placeholderColor={placeholderColor}

                    value={titleBody.title}
                //   handleTitleClick={handleTitleClick}
                //   showTitleToolbar={toolbar.title}
                />
                <EditableBody
                    ref={bodyRef}
                    minHeight={'10rem'}
                    maxHeight={'10rem'}
                    handleBodyChange={handleBodyChange}
                    value={titleBody.body}
                //   handleBodyClick={handleBodyClick}
                //   showBodyToolbar={toolbar.body}
                //   textColor={textColor}
                //   placeholderColor={placeholderColor}
                />

                {/* {showSuggestions ?
          <div className={styles.suggestionBox}>
            <SuggestedOrUpload
              title={'Background Image:'}
            //   handleChange={handleChangeLaBgImg}
            />
          </div> : null} */}
            </div>

            {/* {true && <div className={styles.btnBox}>


        <CustomButton
          btnText={'SAVE'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={captureScreenshot.bind(this)}
        // handleClick={() => setCustomAspectRatio(!customAspectRatio)}
        />
        {isEdit && <CustomButton
          btnText={'CANCEL'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={handleCancel}
        />}
      </div>} */}
        </>
    )

}

export default ImageCard;


const useStyles = makeStyles(() => ({
    imgBox: {
        // position: 'relative',
        borderRadius: '4px',
        minHeight: '50%',
        // height: '50%',
        width: '100%',
        maxWidth: 'calc(420px - 1rem)',
        aspectRatio: 4 / 3,
        '& > img': {
            borderRadius: '4px',
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        },
        '&:hover': {
            border: '1.6px dashed rgb(204, 204, 204, 0.5)',
        }
    },

    titleContainer: {
        // border : '1px solid',
        width: '100%',
        overflow: 'hidden',
        minHeight: '48px',
        margin: '0.8rem 0 !important',
        '& > div': {
            margin: '0 !important',
            maxHeight: '100%',
            height: '100%',
            '&:hover': {
                border: 'none',
            }
        },
        '&:hover': {
            border: '1px dashed rgb(204, 204, 204, 0.5)',
        }
    },
    deleteIcon: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0.5rem',
        right: '0.5rem',
        height: '2.2rem',
        width: '2.2rem',
        // padding: '0.2rem',
        borderRadius: '50%',
        backgroundColor: 'rgb(112,112,112,0.5)',
        '& > img': {
            transition: '0.7s',
            height: '1rem !important',
            width: '1rem !important',
            filter: 'invert(100%) sepia(95%) saturate(21%) hue-rotate(288deg) brightness(104%) contrast(106%)',
        },
        '&:hover': {
            cursor: 'pointer !important',
            '& > img': {
                scale: 1.2,
                filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)'

            }
        }
    }

}));