const FILTER_TYPES = {
    DROP_DOWN: 'DROP_DOWN',
    DATE_RANGE: 'DATE_RANGE',
    DATE: 'DATE',
    INPUT_FIELD: 'INPUT_FIELD',
    INPUT_FIELD_RANGE: 'INPUT_FIELD_RANGE'
}

export const CUSTOM_IDS = {
    COUNT: 'count',
    DISTINCT_COUNT: 'distinct-count',
    DISTINCT_VALUE: 'distinct-value',
    DATE_RANGE: 'date-range',
    SPECIFIC_DATE: 'specific-date',
    STATUS: 'status',
    STATUS_PERCENT: 'status-percent',
    STATUS_COUNT: 'status-count',
    PERCENTAGE: 'percentage',
    LESS_THAN: 'less-than',
    GREATER_THAN: 'greater-than',
    AVERAGE: 'average',
    TIME_VALUE: 'time-value',
    RANGE: 'range',
}

const ATTEMPT_STATUS = [
    { id: 'CORRECT', name: 'CORRECT' },
    { id: 'INCORRECT', name: 'INCORRECT' },
    { id: 'INCOMPLETE', name: 'INCOMPLETE' }
]

const COMPLETION_STATUS = [
    { id: 'COMPLETED', name: 'COMPLETED' },
    { id: 'INPROGRESS', name: 'INPROGRESS' },
    { id: 'NOT_STARTED', name: 'NOT STARTED' },
]

const CHALLENGE_STATUS = [
    { id: 'COMPLETED', name: 'COMPLETED' },
    { id: 'NOT_STARTED', name: 'NOT STARTED' },
]

const LOGIN_STATUS = [
    { id: 'LOGGED_IN', name: 'LOGGED IN' },
    { id: 'NOT_LOGGED_IN', name: 'NOT LOGGED IN' },
]

const customDataPointConstant = [
    {
        id: 'mastero-id',
        name: 'Master-O ID',
        values: [{ name: 'Count', id: CUSTOM_IDS.COUNT, filter: null }, { name: 'Distinct Count', id: CUSTOM_IDS.DISTINCT_COUNT, filter: null }, { name: 'Distinct Value', id: CUSTOM_IDS.DISTINCT_VALUE, filter: FILTER_TYPES.DROP_DOWN }],
        currentFilter: null,
        selectedValue: [],
        group: false
    },
    {
        id: 'attempts',
        name: 'Attempts',
        values: [{ name: 'Status', id: CUSTOM_IDS.STATUS, filter: FILTER_TYPES.DROP_DOWN, data: ATTEMPT_STATUS }],
        currentFilter: null,
        selectedValue: [],
        group: false
    },
    {
        id: 'score',
        name: 'Score',
        values: [{ name: 'Count', id: CUSTOM_IDS.COUNT, filter: null }, { name: 'Average', id: CUSTOM_IDS.AVERAGE, filter: null }, { name: 'Percentage', id: CUSTOM_IDS.PERCENTAGE, filter: FILTER_TYPES.INPUT_FIELD, type: 'percentage' }],
        currentFilter: null,
        selectedValue: [],
        group: false
    },
    {
        id: 'microskill-id',
        name: 'Microskill Name',
        values: [{ name: 'Count', id: CUSTOM_IDS.COUNT, filter: null }, { name: 'Distinct Count', id: CUSTOM_IDS.DISTINCT_COUNT, filter: null }, { name: 'Distinct Value', id: CUSTOM_IDS.DISTINCT_VALUE, filter: FILTER_TYPES.DROP_DOWN }],
        currentFilter: null,
        selectedValue: [],
        group: false
    },
    {
        id: 'completion-status',
        name: 'Completion Status',
        values: [{ name: 'Status', id: CUSTOM_IDS.STATUS, filter: FILTER_TYPES.DROP_DOWN, data: COMPLETION_STATUS }, { name: 'Status Count', id: CUSTOM_IDS.STATUS_COUNT, filter: FILTER_TYPES.DROP_DOWN, data: COMPLETION_STATUS }, { name: 'Status Percent', id: CUSTOM_IDS.STATUS_PERCENT, filter: FILTER_TYPES.DROP_DOWN, data: COMPLETION_STATUS }, { name: 'Less than', id: CUSTOM_IDS.LESS_THAN, filter: FILTER_TYPES.INPUT_FIELD, type: 'percentage' }, { name: 'Greater than', id: CUSTOM_IDS.GREATER_THAN, filter: FILTER_TYPES.INPUT_FIELD, type: 'percentage' }, { name: 'Range', id: CUSTOM_IDS.RANGE, filter: FILTER_TYPES.INPUT_FIELD_RANGE, type: 'percentage' }],
        currentFilter: null,
        selectedValue: [],
        group: false
    },
    {
        id: 'content-launch-date',
        name: 'Content launch date',
        values: [{ name: 'Date Range', id: CUSTOM_IDS.DATE_RANGE, filter: FILTER_TYPES.DATE_RANGE }, { name: 'Specific Date', id: CUSTOM_IDS.SPECIFIC_DATE, filter: FILTER_TYPES.DATE }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },
    {
        id: 'challenges',
        name: 'Challenges',
        values: [{ name: 'Status', id: CUSTOM_IDS.STATUS, filter: FILTER_TYPES.DROP_DOWN, data: CHALLENGE_STATUS }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },
    {
        id: 'completion-date',
        name: 'Completion Date',
        values: [{ name: 'Date range', id: CUSTOM_IDS.DATE_RANGE, filter: FILTER_TYPES.DATE_RANGE }, { name: 'Specific Date', id: CUSTOM_IDS.SPECIFIC_DATE, filter: FILTER_TYPES.DATE }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },
    {
        id: 'completed-in-days',
        name: 'Completed In Days',
        values: [{ name: 'Count', id: CUSTOM_IDS.COUNT, filter: null }, { name: 'Less than', id: CUSTOM_IDS.LESS_THAN, filter: FILTER_TYPES.INPUT_FIELD, type: 'percentage' }, { name: 'Greater than', id: CUSTOM_IDS.GREATER_THAN, filter: FILTER_TYPES.INPUT_FIELD, type: 'percentage' }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },
    {
        id: 'max-score',
        name: 'Max Score',
        values: [{ name: 'Count', id: CUSTOM_IDS.COUNT, filter: null }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },
    {
        id: 'time-spent',
        name: 'Time Spent',
        values: [{ name: 'Time value', id: CUSTOM_IDS.TIME_VALUE, filter: null }, { name: 'Average', id: CUSTOM_IDS.AVERAGE, filter: null }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },

    {
        id: 'login-status',
        name: 'Login Status',
        values: [{ name: 'Status', id: CUSTOM_IDS.STATUS, filter: FILTER_TYPES.DROP_DOWN, data: LOGIN_STATUS }, { name: 'Count', id: CUSTOM_IDS.COUNT, filter: null }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },
    {
        id: 'last-login-date',
        name: 'Last Login Date',
        values: [{ name: 'Date range', id: CUSTOM_IDS.DATE_RANGE, filter: FILTER_TYPES.DATE_RANGE }, { name: 'Specific Date', id: CUSTOM_IDS.SPECIFIC_DATE, filter: FILTER_TYPES.DATE }],
        currentFilter: null,
        selectedValue: [],
        group: false,

    },
]

let nullFilterIds = [];
customDataPointConstant.forEach(element => {
    element.values.forEach(ele => {
        if (ele.filter === null) nullFilterIds.push(ele.id)
    })
})
nullFilterIds = [...new Set(nullFilterIds)]

export default { customDataPointConstant, nullFilterIds };
