import React, { useContext, useState } from 'react';


//mui
import { Box, Button, Typography, Modal, TextField, Stack } from '@mui/material';

//styles
import useStyles from './index.style';

//components
import DemographicData from '../demographic-data';
import CustomDataPoint from '../custom-data-point';
import Label from '../../../custom-styled/label';
import CustomReportTable from '../custom-report-table';

//services
import reportServices from '../../../../services/reports';

//utils
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';
import isEmpty from '../../../../utils/isEmpty';

//context
import { GlobalStates } from '../../../../App';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const ReportBuilder = () => {
    const classes = useStyles();
    let query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates);
    const [templateName, setTemplateName] = useState('');
    const [demographicFilter, setDemographicFilter] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [selectedDataPoint, setSelectedDataPoint] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({});
    let mappingObject = commonUtil.objectify(mapping, "name");
    const [open, setOpen] = useState(false);
    const [showGeneratedTable, setShowGeneratedTable] = useState(false);

    const validate = () => {
        let isValid = true;
        if (!selectedDataPoint.length) {
            globalStates.handleToast(true, 'Please select the data point', 'error')
            return isValid = false
        }
        for (let i = 0; i < selectedDataPoint.length; i++) {
            if (!selectedDataPoint[i].selectedValue.length) {
                globalStates.handleToast(true, `Please select the value for ${selectedDataPoint[i].name}`, 'error');
                return isValid = false
            }

        }
        return isValid
    }

    const handleOpenModal = () => {
        // console.log('selectedDataPoint = ', selectedDataPoint);
        const valid = validate()
        if (!valid) return
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleSaveTemplate = async () => {
        if (!templateName.length) {
            globalStates.handleToast(true, 'Please enter the template name', 'error')
            return
        }
        const payload = getPayload()
        globalStates.handleProgressDialog(true, 'Please wait...')
        const response = await reportServices.customReportSaveTemplate(payload);
        globalStates.handleProgressDialog(false)
        if (response.status === 201) {
            globalStates.handleToast(true, 'Template saved successfully!', 'success')
            handleClose();
        }
    };


    const getMappingDetails = (value) => mappingObject[value];

    const getSelectedDemographics = () => {
        const creationDemographicFilter = demographicFilter[demographicFilter.length - 1];
        demographicFilter.pop();
        let selectedFilters = [];
        for (let i = 0; i < demographicFilter.length; i++) {
            if (demographicFilter[i].selected.length) {
                const columnName = getMappingDetails(demographicFilter[i].id)["table_column"];
                const excelColumn = getMappingDetails(demographicFilter[i].id)["excel_column"];
                let filterObj = { columnName: columnName, values: demographicFilter[i].selected, excelColumn: excelColumn }
                selectedFilters.push(filterObj);
            }
        }

        if (creationDemographicFilter.selected.length) {
            let filterObj = { columnName: 'createdOn', values: creationDemographicFilter.selected, excelColumn: 'createdOn' }
            selectedFilters.push(filterObj);
        }
        demographicFilter.push(creationDemographicFilter);
        return selectedFilters;
    }

    const handleGenerateReport = () => {
        const valid = validate()
        if (!valid) return
        setShowGeneratedTable(true);
    };

    const getCustomDataPoints = () => {
        return selectedDataPoint.map(ele => {
            const key = ele.uniqueId;
            let filter = appliedFilters[key];
            return {
                name: ele.name,
                value: isEmpty(ele.selectedValue[0]) ? null : ele.selectedValue[0],
                filter,
                group: ele.group,
            }
        })
    }

    const getPayload = () => {
        return {
            companyId,
            title: templateName,
            settings: 'string',
            request: {
                demographicData: getSelectedDemographics(),
                customPointData: getCustomDataPoints(),
            }

        }
    }

    return (
        <>
            {!showGeneratedTable
                ?
                <div className={classes.reportBuilder}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <h1 className={classes.heading}>Report Builder</h1>
                        <div className={classes.btnBox}>

                            <Button title='Generate report' onClick={handleGenerateReport} className={`${classes.btn} ${classes.saveBtn}`}>
                                Generate Report
                            </Button>

                            <Button
                                title='Save Template'
                                onClick={handleOpenModal}
                                className={`${classes.btn} ${classes.clearBtn}`}>
                                Save Template
                            </Button>
                        </div>
                    </div>
                    {/* <div className={classes.templateSelector}>
                <select value={template} onChange={handleTemplateChange}>
                    <option value="">Select Template</option>
                    <option value="Template 1">Template 1</option>
                    <option value="Template 2">Template 2</option>
                </select>
            </div> */}

                    <div className={classes.headerContainer}>
                        <div className={classes.headerBox} style={{ width: '30%' }}>
                            <Label labelText={'Select Demographics'} fs='1rem' />
                            <DemographicData
                                demographicFilter={demographicFilter}
                                setDemographicFilter={setDemographicFilter}
                                mapping={mapping}
                                setMapping={setMapping}
                            />
                        </div>

                        <div className={classes.headerBox} style={{ width: "70%" }}>
                            <Label labelText={'Select Data Points'} fs='1rem' />
                            <CustomDataPoint
                                appliedFilters={appliedFilters}
                                setAppliedFilters={setAppliedFilters}
                                selectedDataPoint={selectedDataPoint}
                                setSelectedDataPoint={setSelectedDataPoint}
                            />
                        </div>
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box sx={style}>
                            <Label labelText={'Save Template'} fs='1rem' style={{ marginBottom: '10px' }} />

                            <Stack spacing={2} sx={{ margin: '1rem 0' }}>
                                <TextField
                                    label="Template Name"
                                    variant="outlined"
                                    fullWidth
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveTemplate}
                                    style={{ backgroundColor: '#ff5722' }}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </Box>
                    </Modal>
                </div>
                :
                <CustomReportTable payload={getPayload()} />
            }
        </>
    );
};

export default ReportBuilder;
