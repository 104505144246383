import React, { useEffect, useState, useContext, useRef } from 'react'
import { useParams } from "react-router-dom";

//mui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  FormControl,
  MenuItem,
  Select,
  ListItemText,
  Radio,
} from '@mui/material';

//components
import Title from '../title';
import MicroskillCard from '../ms-card';
import LoadingContainer from '../loading-container';
import SuccessDialog from '../../components/success-dialog';
import FailureDialog from '../../components/failure-dialog';
import SearchBar from './components/search-bar';
import ManageGroupDialog from './components/manage-groups-dialog';
import ConfirmDialog from '../confirm-dialog';
import CustomButton from '../custom-styled/button';
import Label from '../custom-styled/label';

//services
import microskillServices from '../../services/microskill';
import categoryServices from '../../services/categories';
import companiesServices from "../../services/companies";
import groupServices from '../../services/group';

//utils
import useRouter from '../../utils/useRouter';

//style
import useStyles from './index.styles';
import AddCategory from '../create-category';
import commonUtil from '../../utils/commonUtil';

//context
import { GlobalStates } from '../../App';

const ManageMicroskills = () => {
  const styles = useStyles();
  const router = useRouter();
  const { companyId, projectId } = useParams();
  const [globalStates] = useContext(GlobalStates)
  let titleText = "All Microskills"
  const userType = localStorage.getItem('user-type');
  const [loading, setLoading] = useState(true);
  const [collapseList, setCollapseList] = useState([0]);
  const [categoryWiseData, setCategoryWiseData] = useState([]);
  const [allDataCategoryWise, setAllDataCategoryWise] = useState([]);
  const [unCategorizedMs, setUnCategorizedMs] = useState([]);
  const [allUnCategorizedMsData, setAllUnCategorizedMsData] = useState([]);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showAddCategoryPage, setShowAddCategoryPage] = useState(false);
  const [showBackBtnCategory, setShowBackBtnCategory] = useState(true);
  const [manageGroupDialogOpen, setManageGroupDialogOpen] = useState(false);
  const [microskillName, setMicroskillName] = useState('');
  const [groupMsId, setGroupMsId] = useState(null);
  const [msGroupsData, setMsGroupsData] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [isSavingGroups, setIsSavingGroups] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  const [copyMsId, setCopyMsId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [editCategoryItem, setEditCategoryItem] = useState({ id: null });
  const [editCategoryName, setEditCategoryName] = useState('');
  const isDelete = useRef(false);
  const rightBtnText = useRef('Delete');
  const categoryNameRef = useRef();
  const categorySequenceRef = useRef(null);

  //m
  const [companiesData, setCompaniesData] = useState([{ id: null, name: '' }]);
  const companiesDataObject = commonUtil.objectify(companiesData, "id");
  const [selectedCompany, setSelectedCompany] = useState({ id: null, name: 'default' });

  const [showSequenceCta, setShowSequenceCta] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSavingSequence, setIsSavingSequence] = useState(false);
  const [sequenceChangedFor, setSequenceChangedFor] = useState([]);

  useEffect(() => {
    let isScrollToItem = localStorage.getItem('scrollToItem');
    if (isScrollToItem && !showAddCategoryPage) {
      scrollToItem(isScrollToItem);
      localStorage.removeItem('scrollToItem');
    }
  }, [showAddCategoryPage])

  useEffect(() => {
    if (companyId !== 'null') {
      fetchCategories(companyId, { withLoader: true });
      fetchUnCategorizedMs(companyId);
      setSelectedCompany({ id: companyId, name: 'default' });
      fetchCompanyGroups(companyId);
    }

    if (userType === "superAdmin") {
      fetchCompanies();
    }
  }, [])

  useEffect(() => {
    if (userType === "superAdmin" && selectedCompany.id) {
      router.history.replace(`/manage-microskills/${selectedCompany.id}/xx`);
      fetchCategories(selectedCompany.id, { withLoader: true });
      fetchUnCategorizedMs(selectedCompany.id)
      fetchCompanyGroups(selectedCompany.id);
    }

  }, [selectedCompany])

  useEffect(() => {
    if (categoryNameRef.current) {
      categoryNameRef.current.focus();
    }
  }, [editCategoryItem])

  const fetchCompanies = async () => {
    const response = await companiesServices.fetchCompanies();
    if (response.status === 200 || response.status === 201) {
      const data = response.data.data;
      data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      if (companyId === 'null') {
        setSelectedCompany(data[0]);
      }
      setCompaniesData(data);
    }
  }

  const fetchCompanyGroups = async (companyId, options = { startIndex: 0, limit: 1000 }) => {
    const response = await groupServices.fetchGroups(companyId, options);
    if (response.status === 200 || response.status === 201) {
      const data = response.data.data;
      const activeGroups = data.filter(ele => ele.active === true);
      setAllGroups(activeGroups);
    }
  }

  const fetchCategories = async (companyId, { withLoader = true }) => {
    if (withLoader) setLoading(true);
    if (!companyId && companyId !== 'null') {
      return
    }
    const response = await categoryServices.fetchCategories(companyId);
    if (response.status === 200 || response.status === 201) {
      let activeCategories = response.data.data.categoryMicroskills.filter((element, idx) => {
        if (element.active === true) {
          let activeMicroskills = element.Microskills.filter(ele => ele.active === true);
          activeMicroskills.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            } else {
              return 0;
            }
          })

          element.Microskills = activeMicroskills;
          return element
        }
      });

      activeCategories.sort((a, b) => {
        if (a.sequence < b.sequence) {
          return -1;
        } else if (a.sequence > b.sequence) {
          return 1;
        } else {
          return 0;
        }

      })

      let activeCategoriesList = response.data.data.categories.filter(element => element.active === true);

      setCategories(activeCategoriesList);
      setCategoryWiseData(activeCategories);
      setAllDataCategoryWise(activeCategories)
      if (!activeCategories.length) {
        setShowAddCategoryPage(true);
      }
      if (withLoader) setLoading(false);

      let isScrollToNewItem = localStorage.getItem('scrollToNewItem');
      if (isScrollToNewItem) {
        scrollToItem(isScrollToNewItem);
        localStorage.removeItem('scrollToNewItem');
      }
    }
  }

  const fetchUnCategorizedMs = async (companyId) => {
    if (!companyId) {
      return
    }
    const res = await microskillServices.fetchMicroskills(companyId);
    if (res.status === 200 || res.status === 201) {
      let activeMicroskills = res.data.data.filter(element => element.active === true);

      let unCategorized = activeMicroskills.filter(ele => ele.categoryId === null);
      unCategorized.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      })
      setUnCategorizedMs(unCategorized);
      setAllUnCategorizedMsData(unCategorized);
      return
    }
    if (res.status === 400 || res.status === 401) {
      return
    }
  }

  const handleConfirm = async (confirm) => {
    if (confirm) {
      setIsSaving(true);
      let id = confirmationData.data.id;
      if (confirmationData.name === 'microskill') {
        const res = await microskillServices.deleteMicroskill({ id })
        if (res.status === 200 || res.status === 201) {
          globalStates.handleToast(true, `Microskill : ${confirmationData.data.name} Deleted Successfully!`, 'success')
          fetchCategories(companyId, { withLoader: false });
          fetchUnCategorizedMs(companyId);
          setConfirmationData(null);
          setConfirmDialogOpen(false);

        }
      } else if (confirmationData.name === 'copyMs') {
        let payload = {
          oldMicroskillId: confirmationData.data.id,
          newCompanyId: companyId,
          flag: 1
        }

        const response = await microskillServices.copyMicroskill(payload);
        if (response.status === 200) {
          setCopyMsId(response.data.data.id);
          setConfirmationData(null);
          setConfirmDialogOpen(false);
          fetchCategories(companyId, { withLoader: false });
          setTimeout(() => {
            setCopyMsId(null);
          }, 10000)
          setIsSaving(false);
        } else {
          globalStates.handleToast(true, 'Internal server error', 'error')
          setConfirmationData(null);
          setConfirmDialogOpen(false);
          setIsSaving(false);
        }

        return
      } else {
        const response = await categoryServices.deleteCategory({ id });
        if (response.status === 200 || response.status === 201) {
          globalStates.handleToast(true, `Category : ${confirmationData.data.name} Deleted Successfully!`, 'success')
          fetchCategories(companyId, { withLoader: false });
          setConfirmationData(null);
          setConfirmDialogOpen(false);
        }
      }
      setIsSaving(false);
      return
    } else {
      setConfirmationData(null);
      setConfirmDialogOpen(false);
    }
  }

  const handleDeleteMS = (data) => {
    setConfirmationData({ name: 'microskill', data: data });
    setDialogTitle('Confirmation');
    setDialogContent("Are you sure you want to delete this microskill?");
    isDelete.current = true;
    rightBtnText.current = 'Delete';
    setConfirmDialogOpen(true);
    return
  }

  const handleCopyMS = (data) => {
    setConfirmationData({ name: 'copyMs', data: data });
    setDialogTitle('Confirmation');
    setDialogContent("Are you sure you want to copy this microskill?");
    isDelete.current = false;
    rightBtnText.current = 'Copy';
    setConfirmDialogOpen(true);
    return
  }

  const handleEditMS = (data) => {
    router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${data.id}`);
  }

  const createMsCardClick = (item) => {
    localStorage.setItem('category-id', JSON.stringify({ id: item.id, name: item.name, type: 'default' }));
    router.history.push(`/microskill-studio?c_id=${companyId}&&p_id=${projectId}`);
  }

  const createCategory = async (element) => {
    if (element.name.length < 3) {
      globalStates.handleToast(true, 'Minimum 3 characters required', 'error');
      return
    }
    const response = await categoryServices.createCategories(companyId, element.name);
    if (response.status === 200 || response.status === 201) {
      localStorage.setItem('scrollToNewItem', response.data.data.id)
      fetchCategories(companyId, { withLoader: true });
      setShowAddCategoryPage(false);
      return;
    } else {
      globalStates.handleToast(true, 'Internal Server Error', 'error');
    }
  }

  const scrollToItem = (id, block = 'center', blockOffset = null) => {
    let item = document.getElementById(`#${id}`);
    if (item) {
      let scrollOptions = { behavior: 'smooth', block: block };
      if (blockOffset) scrollOptions.blockOffset = blockOffset;
      item.scrollIntoView(scrollOptions);
    }
    return
  }

  const handleDeleteCategory = async (e, item) => {
    // console.log('handleDeleteCategory item = ', item);
    setConfirmationData({ name: 'category', data: item });
    setDialogTitle('Warning!');
    setDialogContent("Deleting a category will leave Microskills uncategorized and disabled for launch unless a category is specified to each microskill")
    isDelete.current = true;
    rightBtnText.current = 'Delete';
    setConfirmDialogOpen(true);
    e.stopPropagation();
    return

  }

  const handleAddNewCategory = () => {
    setShowAddCategoryPage(true);
    setShowBackBtnCategory(true);
    let inputField = document.getElementById("#input-field");
    if (inputField) inputField.focus();
  }

  const handleSelectCompany = (e) => {
    let companyData = e.target.value;
    setSelectedCompany(companyData);
  }

  const handleSearch = (e) => {
    let tempCollapseList = [];
    let searchVal = e.target.value;
    setSearchQuery(searchVal);
    if (searchVal === "") {
      setCategoryWiseData(allDataCategoryWise);
      setUnCategorizedMs(allUnCategorizedMsData);
      setCollapseList([0]);
      return;
    }
    let filterBySearch = [];
    allDataCategoryWise.forEach(ele => {
      let result = ele.Microskills.filter((item) => {
        if (item.name.toLowerCase().includes(searchVal.toLowerCase())) {
          return item;
        }
      })
      if (result.length) {
        let filteredMs = { ...ele, Microskills: result }
        filterBySearch.push(filteredMs);
      }
    })
    setCategoryWiseData(filterBySearch);

    filterBySearch.forEach((ele, i) => {
      tempCollapseList.push(i)
    })
    let filterUncategorized = allUnCategorizedMsData.filter((item) => {
      if (item.name.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    })
    if (filterUncategorized.length) {
      tempCollapseList.push('unCategorized')
    }
    setCollapseList(tempCollapseList);
    setUnCategorizedMs(filterUncategorized);

  }

  const handleViewGroups = async (data) => {
    globalStates.handleProgressDialog(true);
    const res = await microskillServices.fetchMicroskillGroups(data.id);
    if (res.status === 200) {
      setMsGroupsData(res.data.data)
      globalStates.handleProgressDialog(false);
      setMicroskillName(data.name)
      setGroupMsId(data.id);
      setManageGroupDialogOpen(true);
    }

  }

  const handleAddGroup = () => {
    router.history.push(`/groups?c_id=${companyId}`)
  }

  const handleRemoveMsFromGroup = async (ele) => {
    console.log('handleRemoveMsFromGroup ele = ', ele);
    console.log('msGroupsData = ', msGroupsData);
    let exists = msGroupsData.find(item => item.Group.id === ele.id)
    console.log('exists = ', exists);
    if (!exists) {
      let filtered = msGroupsData.filter(element => element.Group.id !== ele.id)
      setMsGroupsData(filtered);
      globalStates.handleToast(true, `${microskillName} successfully removed from ${ele.name}`, 'success')
      return
    }
    let payload = {
      companyId: companyId,
      microskillId: ele.microskillId,
      groupId: ele.id
    }
    const res = await microskillServices.removeMsFromGroup(payload);
    if (res.status === 200 || res.status === 201) {
      let filtered = msGroupsData.filter(element => element.Group.id !== ele.id)
      setMsGroupsData(filtered);
      globalStates.handleToast(true, `${microskillName} successfully removed from ${ele.name}`, 'success')
    }
  }

  const handleSaveGroups = async (groupIds) => {
    let alreadyLinked = [];
    msGroupsData.forEach(ele => {
      alreadyLinked.push(ele.Group.id);
    })
    const removed = alreadyLinked.filter(ele => !groupIds.includes(ele));
    for (let i = 0; i < removed.length; i++) {
      let payload = {
        companyId: companyId,
        microskillId: groupMsId,
        groupId: removed[i]
      }
      await microskillServices.removeMsFromGroup(payload);
    }

    let newSelected = groupIds.filter(ele => {
      if (!alreadyLinked.includes(ele)) {
        return ele
      }
    })
    if (!newSelected.length) {
      setManageGroupDialogOpen(false);
      return
    }
    setIsSavingGroups(true);
    for (let i = 0; i < newSelected.length; i++) {
      let payload = {
        companyId: companyId,
        microskillId: groupMsId,
        groupId: newSelected[i]
      }
      let response = await microskillServices.addMsToGroup(payload);
      if (response.status === 200 || response.status === 201) {
        if (i === newSelected.length - 1) {
          setIsSavingGroups(false);
          setManageGroupDialogOpen(false);
        }
      }

    }

  }

  const SelectRadioBox = (props) => {
    const {
      listData,
      selected = 0,
      valuePlaceholder,
      handleChange,
    } = props;
    // console.log('SelectRadioBox props = ', props);
    return (
      <FormControl >
        {listData.length ? (
          <div style={{ width: '100%' }}>
            {/* <InputLabel id={valuePlaceholder} style={{}}>{valuePlaceholder}</InputLabel> */}
            <Select
              id={selected.id}
              value={selected}
              onChange={handleChange}
              renderValue={(selected) => {
                // console.log('selected = ', selected);
                if (!selected.id)
                  return valuePlaceholder;
                else {
                  // console.log('companiesDataObject = ', companiesDataObject);
                  if (companiesDataObject[null]) return valuePlaceholder;
                  // else 
                  return companiesDataObject[selected.id].name;

                }
              }}
            >

              {listData.map((item) => (
                <MenuItem key={item.id} value={item} >
                  <Radio checked={item.id == selected.id} style={{ color: "#F4511E" }} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}

            </Select>
          </div>
        ) : (
          <Select
            value={selected}
            renderValue={(selected) => {
              // console.log('selected = ', selected);
              return valuePlaceholder;
            }}>
            <MenuItem>
              <ListItemText primary={"No Data Available."} disabled className={styles.ListItemContainer} />
            </MenuItem>
          </Select>
        )}
      </FormControl>
    )
  }

  const handleCollapse = (itemId, idx) => {
    // setShowSequenceCta(false)
    let tempCollapseList = [...collapseList];
    if (tempCollapseList.includes(idx)) {
      let filtered = tempCollapseList.filter(ele => ele !== idx);
      setCollapseList(filtered);
      return
    }
    tempCollapseList.push(idx);
    setCollapseList(tempCollapseList);
    setTimeout(() => {
      // let block = 'start';
      // let blockOffset = -1000;
      // scrollToItem(itemId, block);
      const element = document.getElementById(`#${itemId}`);
      const offsetPixels = -70; // Adjust this value to control the spacing

      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition + offsetPixels, behavior: "smooth" });

    }, 100)
  }

  const handleChangeSequence = (e, element, direction, index) => {
    e.stopPropagation();
    if (categorySequenceRef.current) {
      clearTimeout(categorySequenceRef.current);
    }
    setCollapseList([]);
    setShowSequenceCta(true);
    let tempCategories = [...categoryWiseData];
    let moveUp = []
    let moveDown = []
    if (direction === 'up') {
      let currentCategory = { ...element };
      let categoryAbove = { ...tempCategories[index - 1] };
      moveUp.push(currentCategory.id);
      moveDown.push(categoryAbove.id);
      tempCategories[index] = categoryAbove;
      tempCategories[index - 1] = currentCategory;
    } else {
      let currentCategory = { ...element };
      let categoryBelow = { ...tempCategories[index + 1] };
      moveDown.push(currentCategory.id);
      moveUp.push(categoryBelow.id);
      tempCategories[index] = categoryBelow;
      tempCategories[index + 1] = currentCategory;
    }
    setCategoryWiseData(tempCategories);
    setSequenceChangedFor([...moveUp, ...moveDown]);
    categorySequenceRef.current = setTimeout(() => {
      setSequenceChangedFor([])
    }, 2000)
  }

  const handleUpdateSequence = async () => {
    setIsSavingSequence(true);
    let tempSequenceCategories = [];
    categoryWiseData.forEach((ele, i) => {
      tempSequenceCategories.push({
        categoryId: ele.id,
        sequence: i + 1,
      })
    })
    let payload = {
      companyId: companyId,
      sequenceCategorys: tempSequenceCategories
    }
    const res = await categoryServices.updateCategorySequence(payload);
    if (res && res.status === 200) {
      globalStates.handleToast(true, 'Sequence updated successfully!', 'success');
      setShowSequenceCta(false);
    } else {
      globalStates.handleToast(true, 'Failed to update sequence, please try again!', 'error');
    }
    setIsSavingSequence(false);
  }

  const _undoSequenceChanges = () => {
    setIsSavingSequence(false);
    setShowSequenceCta(false);
    fetchCategories(companyId, { withLoader: false })
  }

  const handleEditCategory = (e, item) => {
    e.stopPropagation();
    setEditCategoryItem(item);
    setEditCategoryName(item.name);
  }
  const handleSaveCategoryChanges = async (e) => {
    e.stopPropagation();
    if (!editCategoryName.length) {
      globalStates.handleToast(true, 'Category name cannot be empty', 'error');
      return
    }
    if (editCategoryName.length < 3) {
      globalStates.handleToast(true, 'Minimum 3 characters required', 'error');
      return
    }
    if (editCategoryItem.name.toLowerCase() === editCategoryName.toLowerCase()) {
      setEditCategoryName('');
      setEditCategoryItem({ id: null });
    } else {
      globalStates.handleProgressDialog(true, 'Please wait...');
      let payload = {
        "id": editCategoryItem.id,
        "name": editCategoryName,
        "type": "Minor"
      }
      const response = await categoryServices.updateCategory(payload);
      if (response && response.status === 200 || response.status === 201) {
        await fetchCategories(companyId, { withLoader: false })
        setEditCategoryName('');
        setEditCategoryItem({ id: null });
        globalStates.handleProgressDialog(false);
        globalStates.handleToast(true, 'Changes saved', 'success');
      } else {
        globalStates.handleProgressDialog(false);
        globalStates.handleToast(true, 'Something went wrong, please try again!', 'error');

      }
    }
    return
  }
  const handleCancelCategoryChanges = (e, item) => {
    e.stopPropagation();
    setEditCategoryName('');
    setEditCategoryItem({ id: null });
  }

  if (loading) {
    return (
      <LoadingContainer />
    )
  } else {
    return (
      <>
        {
          showAddCategoryPage ?
            <AddCategory
              categories={categories}
              showAddCategoryPage={showAddCategoryPage}
              setShowAddCategoryPage={setShowAddCategoryPage}
              createCategory={createCategory}
              showBackBtnCategory={showBackBtnCategory}
              setShowBackBtnCategory={setShowBackBtnCategory}
            /> :
            <div className={styles.mainContainer}>
              <div className={styles.titleContainer}>
                <div className={styles.titleColumn1}>
                  <div className={styles.dropdownBox}>
                    <Title titleText={titleText} infoIcon={true} />

                    {
                      userType === "superAdmin" ?
                        <>
                          <Label labelText={"Select Company:"} fs='0.8rem' />
                          <SelectRadioBox
                            listData={companiesData}
                            valuePlaceholder={"Select Company."}
                            handleChange={handleSelectCompany}
                            selected={selectedCompany}
                          />
                        </> : null
                    }
                  </div>


                  <div className={styles.searchBarBox} >
                    <SearchBar
                      allCategories={categoryWiseData.map(ele => {
                        let tempObj = { ...ele };
                        if ("Microskills" in tempObj) delete tempObj.Microskills;
                        return tempObj
                      })}
                      allCategoryWiseMs={categoryWiseData}
                      unCategorizedMs={unCategorizedMs}
                      handleEditMS={handleEditMS}
                    />
                  </div>

                </div>


                <div className={styles.titleColumn2}>
                  <span className={styles.iconsBox}>
                    {/* <img src='/images/icons/grid.svg' alt='' title='Show List' /> */}
                    {/* <img className={styles.icon} src='/images/icons/grid.svg' alt='' title='Show Grid' /> */}
                  </span>
                </div>
              </div>

              <div className={styles.addCategoryBox}>
                <Button variant="text" startIcon={<AddCircleIcon />} onClick={handleAddNewCategory}>
                  ADD NEW CATEGORY
                </Button>
                {showSequenceCta ? <div className={styles.sequenceCtaBtnBox}>
                  {isSavingSequence ?
                    <LoadingButton
                      loading
                      loadingPosition="center"
                      variant="outlined"
                    >
                    </LoadingButton>
                    : <CustomButton
                      variant='filled'
                      textColor='#fff'
                      borderColor='#f4511e'
                      bgcolor='#f4511e'
                      fs="0.7rem"
                      fw={900}
                      ls={1}
                      btnText={'Save Sequence'}
                      handleClick={handleUpdateSequence}
                    />}
                  <CustomButton
                    textColor='#f4511e'
                    borderColor='#f4511e'
                    bgcolor='#fff'
                    fs="0.7rem"
                    fw={900}
                    ls={1}
                    btnText={'Cancel'}
                    handleClick={_undoSequenceChanges}
                  />
                </div> : null}
              </div>
              <div className={styles.list}>
                {
                  categoryWiseData.map((item, index) => (
                    <div
                      key={index}
                      id={`#${item.id}-xx`}
                      style={{
                        height: !collapseList.includes(index) ? '68px' : '',
                      }}
                      className={`${styles.listItem}`}>
                      <div id={`#${item.id}`} className={`${styles.categoryNameBox} ${sequenceChangedFor.includes(item.id) ? styles.highlighted : ""}`} onClick={() => handleCollapse(item.id, index)}>
                        <i className={`fa-solid ${collapseList.includes(index) ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                        {editCategoryItem.id === item.id ?
                          <h5>
                            <input
                              ref={editCategoryItem.id === item.id ? categoryNameRef : null}
                              onClick={(e) => e.stopPropagation()}
                              value={editCategoryName}
                              onChange={(e) => setEditCategoryName(e.target.value)}
                              type="text"
                              placeholder="Category name"
                              maxLength={50}
                            />
                            <i onClick={(e) => handleSaveCategoryChanges(e)} className="fa-solid fa-check" title='Save'></i>
                            <i onClick={(e) => handleCancelCategoryChanges(e)} className="fa-solid fa-xmark" title='Cancel'></i>
                          </h5>
                          : <h5>
                            {item.name} {`(${item.Microskills.length})`}
                            {!searchQuery.length && <img className={styles.editIcon} src='/images/icons/pencil-edit-button.svg' alt='' title='Edit' onClick={(e) => handleEditCategory(e, item)} />}
                          </h5>}
                        <img className={styles.deleteIcon} src='/images/icons/delete-photo.svg' alt='' title='Delete Category' onClick={(e) => handleDeleteCategory(e, item)} />
                        {!searchQuery.length ?
                          <>
                            {index > 0 ? <i onClick={(e) => handleChangeSequence(e, item, 'up', index)} title='Move up' className="fa-solid fa-circle-arrow-up"></i> : null}
                            {index < categoryWiseData.length - 1 ? <i onClick={(e) => handleChangeSequence(e, item, 'down', index)} title='Move down' className="fa-solid fa-circle-arrow-down"></i> : null}
                          </> : null}

                        {/* <i class="fa-solid fa-circle-chevron-up"></i> */}
                        {/* <i class="fa-solid fa-circle-chevron-down"></i> */}
                      </div>

                      {collapseList.includes(index) ? <div className={styles.cardsContainer}>
                        <div className={styles.createCard} onClick={() => createMsCardClick(item)}>
                          <img onClick={() => createMsCardClick(item)} src='/images/icons/add.svg' alt='' />
                          <h5>CREATE NEW MICROSKILL</h5>
                        </div>
                                
                        {console.log(`Data:${JSON.stringify(item,null,2)}`)}
                        {
                          item.Microskills.map((element, index) => (
                            <React.Fragment key={index}>
                              <MicroskillCard
                                data={element}
                                handleEditMS={handleEditMS}
                                handleCopyMS={handleCopyMS}
                                handleDeleteMS={handleDeleteMS}
                                handleViewGroups={handleViewGroups}
                                copyMsId={copyMsId}
                              />
                            </React.Fragment>
                          ))
                        }
                      </div> : null}

                    </div>
                  ))
                }
              </div>



              {unCategorizedMs.length ?
                <>
                  <hr style={{ marginTop: '1.5rem' }}></hr>
                  <div id='#un-categorized' className={styles.categoryNameBox} onClick={() => handleCollapse("un-categorized", 'unCategorized')}>
                    <i className={`fa-solid ${collapseList.includes('unCategorized') ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                    <h5>
                      Uncategorized {`(${unCategorizedMs.length})`}
                      {/* <img className={styles.editIcon} src='/images/icons/pencil-edit-button.svg' alt='' title='Edit' /> */}
                    </h5>
                  </div>

                  {collapseList.includes('unCategorized') ?
                    <div className={styles.cardsContainer} style={{ marginBottom: '16px' }}>
                      {unCategorizedMs.map((element, index) => (
                        <MicroskillCard
                          key={index}
                          data={element}
                          handleEditMS={handleEditMS}
                          handleCopyMS={handleCopyMS}
                          handleDeleteMS={handleDeleteMS}
                          handleViewGroups={handleViewGroups}
                          copyMsId={copyMsId}
                        />
                      ))}
                    </div> : null}
                </> : null}



            </div>
        }


        {
          successDialogOpen &&
          <SuccessDialog
            successDialogOpen={successDialogOpen}
            setSuccessDialogOpen={setSuccessDialogOpen}
            dialogText={dialogContent}
            handleClick={() => setSuccessDialogOpen(false)}
            buttonText='Okay'
          />
        }
        {
          errorDialogOpen &&
          <FailureDialog
            errorDialogOpen={errorDialogOpen}
            setErrorDialogOpen={setErrorDialogOpen}
            dialogText={dialogContent}
            handleClick={() => setErrorDialogOpen(false)}
          />
        }
        {
          confirmDialogOpen &&
          <ConfirmDialog
            open={confirmDialogOpen}
            setOpen={setConfirmDialogOpen}
            isDelete={isDelete.current}
            title={dialogTitle}
            description={dialogContent}
            leftBtnClick={() => handleConfirm(false)}
            rightBtnClick={() => handleConfirm(true)}
            rightBtnText={rightBtnText.current}
            isSaving={isSaving}
          />
        }
        {
          manageGroupDialogOpen &&
          <ManageGroupDialog
            manageGroupDialogOpen={manageGroupDialogOpen}
            setManageGroupDialogOpen={setManageGroupDialogOpen}
            handleAddGroup={handleAddGroup}
            microskillName={microskillName}
            msGroupsData={msGroupsData}
            handleRemoveMsFromGroup={handleRemoveMsFromGroup}
            allGroups={allGroups}
            handleSave={handleSaveGroups}
            isSaving={isSavingGroups}
          />
        }
      </>
    )
  }


}

export default ManageMicroskills;
